import t from "../../actions/WalletActions/types";

const initialState = {
  walletList: {
    content: [],
    wallet: []
  },
  merchantWalletList: {
    wallet: []
  },
  allWalletList: {},
  walletDetails: {},
  updatedWallet: {},
  transferRecipt: {}
};

export default (state = initialState, action) => {
  switch (action.type) {
    case t.FETCHED_WALLET:
      let allWalletList = action.payload
      return {
        ...state,
        walletList: action.payload,
        allWalletList
      };

    case t.FETCHED_MERCHANT_WALLET:
      let oldWalletList = state.walletList;
      oldWalletList["content"] = action.payload ? action.payload.wallet ? action.payload.wallet : action.payload.content : [];
      return {
        ...state,
        walletList: oldWalletList,
      };

    case t.FETCHED_WALLET_DETAILS:
      //console.log("details", action.payload);
      return {
        ...state,
        walletDetails: action.payload
      }

    case t.RECEIPTFILE:
      //console.log("details", action.payload);
      return {
        ...state,
        transferRecipt: action.payload
      }


    default:
      return state;
  }
};
