import t from "./types";
import { Api } from "../../services/httpService";
import moment from "moment";

// Get all TraditionalRequest List

export const getTraditionalRequest = (status, activePage, size) => async (dispatch) => {
  const res = status ? await Api("get", `/traditionalrequests?status=${status}`) : await Api("get", `/traditionalrequests?page=${activePage}&size=${size}`);
  if (res.success) {
    await dispatch({ type: t.FETCHED_TRADITIONAL_REQUEST, payload: res.body });
  }
};
export const getSearchTraditionalRequest = (requestId) => async (dispatch) => {
  const res = await Api("get", `/traditionalrequests?requestId=${requestId}`)
  if (res.success) {
    await dispatch({ type: t.FETCHED_TRADITIONAL_REQUEST, payload: res.body });
  }
};

// Get single Merchant list
export const getMerchantTraditionalRequest = (merchantId, buyerPlatformId, isInvoice = false) => async (
  dispatch
) => {
  if (isInvoice == true && merchantId) {
    var res = await Api("get", `/traditionalrequests/${merchantId}/${buyerPlatformId}`);
  } else if (isInvoice == true && !merchantId) {
    var res = await Api("get", `/traditionalrequests?buyerPlatformsId=${buyerPlatformId}`);
  } else {
    var res = await Api("get", `/traditionalrequests/${merchantId}`);

  }
  await dispatch({
    type: t.FETCHED_MERCHANT_TRADITIONAL_REQUEST,
    payload: res.body,
  });
};

// Add TraditionalRequest record

export const addTraditionalRequest = (requestParam, traditionalPdf) => async (dispatch) => {
  let merchantBuyerPlatformsId = {};
  merchantBuyerPlatformsId = {
    "merchantId": requestParam.merchantId,
    "buyerPlatformsId": requestParam.buyerPlatformId
  }
  let newParam = Object.assign({}, requestParam, { "merchantBuyerPlatformsId": merchantBuyerPlatformsId });
  // bankDetail
  let bankData = { ...newParam.bankDetail }
  delete newParam.bankDetail
  // delete newParam.traditionalPdf;
  //const res = await Api('post', `traditionalrequests/entry?merchantId=${requestParam.merchantId}&userid=${requestParam.merchantId}&buyerPlatformId=${requestParam.buyerPlatformId}&invoices=${requestParam.invoices}&advanceAmount=${requestParam.advanceAmount}&advancePaymentDate=${requestParam.advancePaymentDate}&requestedAmount=${requestParam.requestedAmount}&status=${requestParam.status}&feeAmount=${requestParam.feeAmount}&settlementDate=${requestParam.settlementDate}&proposalAcceptanceDate=${requestParam.proposalAcceptanceDate}`);
  if (!requestParam.status !== "Confirmed for Payment") {
    const res = await Api("post", `/traditionalrequests/entry`, newParam);
    if (res.success === true) {
      if (traditionalPdf) {
        let form_data = new FormData()
        form_data.append('file', traditionalPdf, traditionalPdf.name)
        form_data.append('traditionalRequestsId', res.body.id)
        form_data.append('buyerPlatformsId', newParam.buyerPlatformId)
        form_data.append("merchantId", newParam.merchantId);
        dispatch(traditionalRequestPdf(form_data));
      }
    }

    if (res.body?.status === "Approved" && Object.keys(requestParam.fwfPartnerData).length <= 0) {
      bankData["paymentAmount"] = bankData.paymentAmount - newParam.adhocAdjustment;
      bankData["requestId"] = res.body?.requestId
      bankData["paymentRemarks"] = res.body?.requestId + " " + moment(res.body?.creationTimestamp).format("MMDDYYYY")
      dispatch(bankDetailSubmit(bankData))
    }
  }

  if (requestParam.status === "Confirmed for Payment" && requestParam.fwfPartnerData && Object.keys(requestParam.fwfPartnerData).length > 0) {
    bankData["paymentAmount"] = bankData.paymentAmount;
    bankData["requestId"] = requestParam.requestId
    bankData["paymentRemarks"] = requestParam.requestId + " " + moment(requestParam.creationTimestamp).format("MMDDYYYY")
    dispatch(bankDetailSubmit(bankData))
  }

};

// Edit TraditionalRequest record

export const getTraditionalRequestDetails = (traditionalRequestId) => async (dispatch) => {
  const res = await Api("get", `/traditionalrequests/${traditionalRequestId}`);
  if (res.success) {
    await dispatch({
      type: t.FETCHED_TRADITIONAL_REQUEST_DETAILS,
      payload: res.body,
    });
  } else {
    await dispatch({
      type: t.FETCHED_TRADITIONAL_REQUEST_DETAILS,
      payload: res.body,
    });
  }
};

//Update TraditionalRequest Record
export const updateTraditionalRequest = (requestParam, traditionalPdf) => async (dispatch) => {

  let merchantBuyerPlatformsId = {
    "merchantId": requestParam.merchantId,
    "buyerPlatformsId": requestParam.buyerPlatformId
  };
  let newParam = Object.assign({}, requestParam, { "merchantBuyerPlatformsId": merchantBuyerPlatformsId });
  // bankDetail
  let bankData = { ...newParam.bankDetail }
  delete newParam.bankDetail
  // console.log("newParam:::::::::::", newParam)
  // delete newParam.traditionalPdf;
  //const res = await Api("post",`/wallet/entry?merchantId=${requestParam.merchantId}&transactionType=${requestParam.transactionType}&transactionRemarks=${requestParam.transactionRemarks}&amount=${requestParam.amount}&balance=${requestParam.balance}&id=${requestParam.id}`);


  if (!requestParam.status !== "Confirmed for Payment") {
    const res = await Api("post", `/traditionalrequests/entry`, newParam);
    if (res.success === true) {
      if (traditionalPdf) {
        let form_data = new FormData()
        form_data.append('file', traditionalPdf, traditionalPdf.name)
        form_data.append('traditionalRequestsId', res.body.id)
        form_data.append('buyerPlatformsId', newParam.buyerPlatformId)
        form_data.append("merchantId", newParam.merchantId);
        dispatch(traditionalRequestPdf(form_data));
      }
    }

    if (res.body?.status === "Approved" && Object.keys(requestParam.fwfPartnerData).length <= 0) {
      bankData["paymentAmount"] = bankData.paymentAmount - newParam.adhocAdjustment;
      bankData["requestId"] = res.body?.requestId
      bankData["paymentRemarks"] = res.body?.requestId + " " + moment(res.body?.creationTimestamp).format("MMDDYYYY")
      dispatch(bankDetailSubmit(bankData))
    }
  }

  if (requestParam.status === "Confirmed for Payment" && requestParam.fwfPartnerData && Object.keys(requestParam.fwfPartnerData).length > 0) {
    bankData["paymentAmount"] = bankData.paymentAmount;
    bankData["requestId"] = requestParam.requestId
    bankData["paymentRemarks"] = requestParam.requestId + " " + moment(requestParam.creationTimestamp).format("MMDDYYYY")
    dispatch(bankDetailSubmit(bankData))
  }


};
export const bankDetailSubmit = (bankDetail) => dispatch => {
  Api('POST', '/bank/bankpayment/entry', bankDetail)
    .then(res => console.log('bankDetail res', res))
    .catch(error => console.log('error', error))
}
// export const traditionalRequestPdf = (params) => async (dispatch) => {
//   const res = await Api('post', '/traditionalrequests/pdf', params, true);
//   if (res) {
//     await dispatch({ type: t.TRADITIONAL_REQUEST_PDF, payload: res });
//   }
// }
export const traditionalRequestPdf = (params) => async (dispatch) => {
  const res = await Api('post', `/traditionalrequests/pdf`, params, true);
  // if(res){
  //     await dispatch({ type: t.INVOICE_PDF, payload: res }); 
  // }
}

export const setMerchantForSearch = (data) => async (dispatch) => {
  await dispatch({ type: t.SET_MERCHANT_FOR_SEARCH, payload: data })
}
export const resetMerchantForSearch = () => async (dispatch) => {
  await dispatch({ type: t.RESET_MERCHANT_FOR_SEARCH })
}

export const setBuyerplatformForSearch = (data) => async (dispatch) => {
  await dispatch({ type: t.SET_BUYERPLATFORM_FOR_SEARCH, payload: data })
}
export const setRequestForSearch = (data) => async (dispatch) => {
  await dispatch({ type: t.SET_REQUEST_FOR_SEARCH, payload: data })
}
export const setStatusForSearch = (data) => async (dispatch) => {
  await dispatch({ type: t.SET_STATUS_FOR_SEARCH, payload: data })
}

{/* Get Recoveries */ }

export const getRecoveries = (type) => async (dispatch) => {
  try {
    const res = await Api('get', "/pfpclient/recoveries/findAll");
    if (type === "Request") {
      await dispatch({ type: t.GET_REQUEST_RECOVERIES, payload: res });
    } else {
      await dispatch({ type: t.GET_PENDING_RECOVERIES, payload: res });
    }
  } catch (error) {
    console.log("Error", error);
  }
}


{/** Admin check  */ }

export const getGenericsStoreProcedure = (param) => async (dispatch) => {
  try {
    const res = await Api("get", `/common/getGenericSPData/adminChecks?merchantid=${param.merchantId}&buyerplatformid=${param.buyerPlatformId}`);
    dispatch({ type: t.GET_STORE_PROCEDURE, payload: res })
  } catch (error) {
    console.log("Error", error);
  }
}




