import t from "./types";
import { Api } from '../../services/httpService';

// Get all wallet List 

export const getPurchaseOrder = () => async (dispatch) => {
    const res = await Api('get', `/PurchaseOrder`);
    if (res.success) {
        await dispatch({ type: t.FETCHED_PURCHASE_ORDER, payload: res.body });
    }
}

// Get single Merchant list
export const getMerchantPurchaseOrder = (merchantId, buyerPlatformId, isInvoice = false) => async (dispatch) => {
    if (isInvoice == true && merchantId) {
        var res = await Api('get', `/PurchaseOrder/${merchantId}/${buyerPlatformId}`);

    } else if (isInvoice == true && !merchantId) {
        var res = await Api('get', `/PurchaseOrder?buyerPlatformsId=${buyerPlatformId}`);
    } else {
        var res = await Api('get', `/PurchaseOrder/${merchantId}`);

    }

    await dispatch({ type: t.FETCHED_MERCHANT_PURCHASE_ORDER, payload: res.body });

}

// Add PurchaseOrder record

export const addPurchaseOrder = (requestParam) => async (dispatch) => {
    let merchantBuyerPlatformsId = {};
    merchantBuyerPlatformsId = {
        "merchantId": requestParam.merchantId,
        "buyerPlatformsId": requestParam.buyerPlatformsId
    }
    let newParam = Object.assign({}, requestParam, { "merchantBuyerPlatformsId": merchantBuyerPlatformsId });

    const res = await Api('post', `/PurchaseOrder/entry`, newParam);

    let newParamForInvoices = Object.assign({}, requestParam, { "purchaseOrderId": res && res.body ? res.body.id : "" });
    dispatch(addPurchaseOrderInvoices(newParamForInvoices));
}


// Edit PurchaseOrder record 

export const getPurchaseOrderDetails = (purchaseOrderId) => async (dispatch) => {
    const res = await Api('get', `/PurchaseOrder/${purchaseOrderId}`);

    if (res.success) {
        await dispatch({ type: t.FETCHED_PURCHASE_ORDER_DETAILS, payload: res.body });
    } else {
        await dispatch({ type: t.FETCHED_PURCHASE_ORDER_DETAILS, payload: res.body });
    }
}


//Update PurchaseOrder Record
export const updatePurchaseOrder = (requestParam) => async (dispatch) => {

    const res = await Api('post', `/PurchaseOrder/entry`, requestParam);
}


// get purchase Order Invoice 

export const getPurchaseOrderInvoicesDetails = (purchaseOrderId) => async (dispatch) => {
    const res = await Api('get', `/purchaseorderinvoices/${purchaseOrderId}`);

    if (res.success) {
        await dispatch({ type: t.FETCHED_PURCHASE_ORDER_INVOICES_DETAILS, payload: res.body });
    } else {
        await dispatch({ type: t.FETCHED_PURCHASE_ORDER_INVOICES_DETAILS, payload: res.body });
    }
}

export const addPurchaseOrderInvoices = (requestParam) => async (dispatch) => {

    const res = await Api('post', `/purchaseorderinvoices/entry`, requestParam);
}

//Update PurchaseOrder Record
export const updatePurchaseOrderInvoices = (requestParam) => async (dispatch) => {
    const res = await Api('post', `/purchaseorderinvoices/entry`, requestParam);
}


// Update Invoices Purchase Order Data


export const updateInvoices = (requestParam) => async (dispatch) => {
    const res = await Api('post', `/invoices/entry`, requestParam);
}



