import t from "../../actions/ConfigurationActions/types";

const initialState = {
  configList: [],
  masterDetails: {}
};

export default (state = initialState, action) => {
  switch (action.type) {
    case t.CONF_ALL_LIST:
      return {
        ...state,
        configList: action.payload.body,
      };
      break;
    case t.GET_CONFIG_DETAILS:
      return {
        ...state,
        masterDetails: action.payload.body
      }
    default:
      return state;
  }
};
