export default {
    FETCHED_INVOICES: 'FETCHED_INVOICES',
    FETCHED_MERCHANT_INVOICES: 'FETCHED_MERCHANT_INVOICES',
    FETCHED_INVOICES_DETAILS: 'FETCHED_INVOICES_DETAILS',
    CREATE_INVOICE: 'CREATE_INVOICE',
    INVOICE_PDF: 'INVOICE_PDF',
    INVOICE_ID: 'INVOICE_ID',
    INVOICES_EXCEL_SHEET: 'INVOICES_EXCEL_SHEET',
    FILTER_INVOICES_LIST: 'FILTER_INVOICES_LIST',
    CLEAR_INVOICE_LIST: 'CREATE_INVOICE',
    RESEND_EMAIL: 'RESEND_EMAIL',
    DELETE_INVOICE: 'DELETE_INVOICE',
    RESTORE_DELETED_INVOICE: 'RESTORE_DELETED_INVOICE',
    DELETE_INVOICES_LIST: 'DELETE_INVOICES_LIST',
    RESET_INVOICE_ID:"RESET_INVOICE_ID"

}