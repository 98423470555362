import t from "./types";
import { Api } from "../../services/httpService";

/* GET ALL NOTIFICATION TEMPLETE LIST */
export const getAll = () => async (dispatch) => {
    const res = await Api("get", `/common/master/findAll`);
    if (res.success) {
        dispatch({ type: t.CONF_ALL_LIST, payload: res });
    } else {
        console.log("action error");
    };
};

/* UPDATE NOTIFICATION TEMPLATE */

export const saveConfigData = (params) => async (dispatch) => {
    const res = await Api("post", `/common/master/entry`, params);
    if (res.success) {
        dispatch({ type: t.ADD_CONFIG, payload: res });
    }
};

export const getMasterDetails = (id) => async (dispatch) => {
    const res = await Api("GET", `/common/master/findById?id=${id}`);
    if (res.success) {
        dispatch({ type: t.GET_CONFIG_DETAILS, payload: res });
    }
};

export const saveMasterData = (params) => async (dispatch) => {
    const res = await Api("post", `/common/masterdata/entry`, params);
    if (res.success) {
        dispatch({ type: t.ADD_MASTER_DATA, payload: res });
    }
}
