import t from "./types";
import { Api } from "../../services/httpService";

/* GET ALL NOTIFICATION TEMPLETE LIST */

export const getCmsPages = () => async (dispatch) => {
    dispatch({ type: t.FETCHED_CMS_PAGE_LOADING, payload: true });
    const res = await Api("get", `/staticValues`);
    if (res.success) {
        await dispatch({ type: t.FETCHED_CMS_PAGE, payload: res.body });
    } else {
        if(res.status == 401){
            window.location = '/admin/page/UnAuthorized';
            await dispatch({ type: t.FETCHED_CMS_PAGE_ERROR, payload: res });
        }
        else{
            console.log("error----", res);
            await dispatch({ type: t.FETCHED_CMS_PAGE_ERROR, payload: res });
        }
    };
    dispatch({ type: t.FETCHED_CMS_PAGE_LOADING, payload: false });
};

/* UPDATE NOTIFICATION TEMPLATE */

export const cmsPageEntry = (params) => async (dispatch) => {
    dispatch({ type: t.CMS_PAGE_ENTRY_LOADING, payload: true });
    const res = await Api("post", `/staticValues/entry`, params);
    if (res.success) {
        await dispatch({ type: t.CMS_PAGE_ENTRY, payload: res.body });
    } else {
        await dispatch({ type: t.CMS_PAGE_ENTRY_ERROR, payload: res });
    };
    dispatch({ type: t.FETCHED_CMS_PAGE_LOADING, payload: false });
};

export const getCmsPage = (id) => async (dispatch) => {
    dispatch({ type: t.FETCHED_CMS_PAGE_LOADING, payload: true });
    const res = await Api("get", `/staticValues/${id}`);
    if (res.success) {
        await dispatch({ type: t.FETCHED_CMS_PAGE_SINGLE, payload: res.body });
    } else {
        await dispatch({ type: t.FETCHED_CMS_PAGE_ERROR, payload: res });
    };
    dispatch({ type: t.FETCHED_CMS_PAGE_LOADING, payload: false });
};
