import t from "./types";
import { Api } from '../../services/httpService';

// Add document record
export const addEditDocumentMonitoring = (param) => async (dispatch) => {

    const res = await Api('post', `/common/accountDocument/entry`, param);
    if (res.success) {
        await dispatch({ type: t.ADD_EDIT_DOCUMENT_MONITORING, payload: res.body });
    }
}

// Edit document record 
export const getDocumentDetails = (docId) => async (dispatch) => {
    try {
        const res = await Api('get', `/common/accountDocument/findById/${docId}`);

        if (res.success) {
            await dispatch({ type: t.FETCHED_DOCUMENT_MONITORING_DETAILS, payload: res.body });
        }
        else {
            if(res.status == 401){
                window.location = '/admin/page/UnAuthorized';
            }
            else{
                console.log("error----", res);
            }
        }
    } catch (error) {
        console.log("Error", error);
    }
};



export const uploadDocPdf = (docId, file) => async (dispatch) => {
    let form_data = new FormData();
    form_data.append('file', file, file.name)
    form_data.append('accountDocumentId', docId)
    const res = await Api('post', `/common/accountDocument/upload`, form_data, true);

};

export const DocView = (docPath) => async (dispatch) => {
    const res = await Api('get', `/common/docView?filePath=${docPath}`);
    if (res.success) {
        await dispatch({ type: t.FETCHED_DOCUMENT_MONITORING_FILE, payload: res.body });
    }
}


