export default {
    FETCHED_TRADITIONAL_REQUEST: 'FETCHED_TRADITIONAL_REQUEST',
    FETCHED_MERCHANT_TRADITIONAL_REQUEST: 'FETCHED_MERCHANT_TRADITIONAL_REQUEST',
    FETCHED_TRADITIONAL_REQUEST_DETAILS: 'FETCHED_TRADITIONAL_REQUEST_DETAILS',
    TRADITIONAL_REQUEST_PDF: "TRADITIONAL_REQUEST_PDF",
    SET_MERCHANT_FOR_SEARCH: 'SET_MERCHANT_FOR_SEARCH',
    RESET_MERCHANT_FOR_SEARCH: 'RESET_MERCHANT_FOR_SEARCH',
    SET_BUYERPLATFORM_FOR_SEARCH: 'SET_BUYERPLATFORM_FOR_SEARCH',
    SET_REQUEST_FOR_SEARCH: 'SET_REQUEST_FOR_SEARCH',
    SET_STATUS_FOR_SEARCH: 'SET_STATUS_FOR_SEARCH',
    GET_REQUEST_RECOVERIES: "GET_REQUEST_RECOVERIES",
    GET_PENDING_RECOVERIES: "GET_PENDING_RECOVERIES",
    GET_STORE_PROCEDURE: "GET_STORE_PROCEDURE"
}