import t from "./types";
import { Api } from "../../services/httpService";


export const getCommitmentList = () => async (dispatch) => {
    const res = await Api('get', `./pfpclient/commitment/findAll`);
    if (res.success) {
        await dispatch({ type: t.FETCHED_PFP_COMMITMENTS, payload: res.body });
    } else {
        await dispatch({ type: t.FETCHED_PFP_COMMITMENTS, payload: res.body });
    }
}

export const getAllCommitmentList = (investorId, pageNo, size, fromDate, toDate) => async (dispatch) => {
    let pageSize = {
        "size": size ?? 20,
        "page": pageNo ?? 0,
    }
    let investor;
    if (investorId) {
        investor = {
            "investorId": investorId ?? ""
        }
    }
    let dates;
    console.log("fromDate", fromDate);
    if (fromDate) {
        dates = {
            "startDate": fromDate,
            "endDate": toDate ?? null
        }
    }

    const res = await Api('post', `./pfpclient/commitment/findAsParam`, Object.assign(pageSize, investor, dates));
    if (res.success) {
        await dispatch({ type: t.FETCHED_ALL_COMMITMENTS, payload: res.body });
    } else {
        await dispatch({ type: t.FETCHED_ALL_COMMITMENTS, payload: res.body });
    }
}

export const getCouponsList = () => async (dispatch) => {
    const res = await Api('get', `/pfpclient/coupon/findAll`);
    if (res.success) {
        await dispatch({ type: t.FETCHED_ALL_COUPONS, payload: res.body });
    } else {
        await dispatch({ type: t.FETCHED_ALL_COUPONS, payload: res.body });
    }
}

export const getCoupons = (investorId, pageNo, size, fromDate, toDate) => async (dispatch) => {
    let pageSize = {
        "size": size ?? 20,
        "page": pageNo ?? 0
    }
    let investor;
    if (investorId) {
        investor = {
            "investorId": investorId ?? ""
        }
    }
    let dates;
    if (fromDate) {
        dates = {
            "startDate": fromDate ?? "",
        }
        if(toDate){
            Object.assign(dates,{"endDate": toDate})
        }
    }
    const res = await Api('post', `/pfpclient/coupon/findAsParam`, Object.assign(pageSize, investor, dates));
    if (res.success) {
        await dispatch({ type: t.FETCHED_FILTERED_COUPONS, payload: res.body });
    } else {
        await dispatch({ type: t.FETCHED_FILTERED_COUPONS, payload: res.body });
    }
}

export const getCouponParams = (investorId, pageNo, size, fromDate, toDate) => async (dispatch) => {
    let pageSize = {
        "size": size ?? 20,
        "page": pageNo ?? 0
    }
    let investor;
    if (investorId) {
        investor = {
            "investorId": investorId ?? ""
        }
    }
    let dates;
    if (fromDate) {
        dates = {
            "investmentStartDate": fromDate ?? "",
        }
        if(toDate){
            Object.assign(dates,{"investmentMaturityDate": toDate})
        }
    
    }
    const res = await Api('post', `/pfpclient/couponParam/findAsParam`, Object.assign(pageSize, investor, dates));
    if (res.success) {
        await dispatch({ type: t.FETCHED_FILTERED_COUPON_PARAM, payload: res.body });
    } else {
        await dispatch({ type: t.FETCHED_FILTERED_COUPON_PARAM, payload: res.body });
    }
}

export const getAllTransactions = () => async (dispatch) => {
    const res = await Api('get', `/pfpclient/ledger/findAll`);
    if (res.success) {
        await dispatch({ type: t.FETCHED_TRANSACTIONS, payload: res.body });
    } else {
        await dispatch({ type: t.FETCHED_TRANSACTIONS, payload: res.body });
    }
}

export const getTransactionsList = (investorId, pageNo, size, fromDate, toDate) => async (dispatch) => {
    let pageSize = {
        "size": size ?? 20,
        "page": pageNo ?? 0
    }
    let investor;
    if (investorId) {
        investor = {
            "investorId": investorId ?? ""
        }
    }
    let dates;
    if (fromDate) {
        dates = {
            "startDate": fromDate ?? "",
            "endDate": toDate ?? null
        }
    }
    const res = await Api('post', `/pfpclient/ledger/findAsParam`, Object.assign(pageSize, investor, dates));
    if (res.success) {
        await dispatch({ type: t.FETCHED_ALL_TRANSACTIONS, payload: res.body });
    } else {
        await dispatch({ type: t.FETCHED_ALL_TRANSACTIONS, payload: res.body });
    }
}

export const getPfpBankStatementList = (investorId, pageNo, size, fromDate, toDate) => async (dispatch) => {
    let pageSize = {
        "size": size ?? 20,
        "page": pageNo ?? 0,
        "type": "clientMoneyBankStatement"
    }
    let investor;
    if (investorId) {
        investor = {
            "merchantId": investorId ?? ""
        }
    }
    let dates;
    if (fromDate) {
        console.log("this.state.fromDate",fromDate);
        dates = {
            "fromDate": fromDate ?? "",
        }
        if(toDate){
            Object.assign(dates,{"toDate": toDate})
        }
    }
    const res = await Api('post', `/common/accountDocument/findAsParam`, Object.assign(pageSize, investor, dates));
    if (res.success) {
        await dispatch({ type: t.FETCHED_ALL_PFP_BANK_STATEMENTS, payload: res.body });
    } else {
        await dispatch({ type: t.FETCHED_ALL_PFP_BANK_STATEMENTS, payload: res.body });
    }
}


export const getTransactionsById = (id) => async (dispatch) => {

    const res = await Api('get', `/pfpclient/ledger/${id}`);
    if (res.success) {
        await dispatch({ type: t.FETCHED_TRANSACTION_BY_ID, payload: res.body });
    } else {
        await dispatch({ type: t.FETCHED_TRANSACTION_BY_ID, payload: res.body });
    }
}

export const getBankStatementById = (id) => async (dispatch) => {

    const res = await Api('get', `/bank/getClientMoneyStatementById/${id}`);
    if (res.success) {
        await dispatch({ type: t.FETCHED_PFPBANKSTATEMENT_BY_ID, payload: res.body });
    } else {
        await dispatch({ type: t.FETCHED_PFPBANKSTATEMENT_BY_ID, payload: res.body });
    }
}

export const getDocumentById = (id) => async (dispatch) => {

    const res = await Api('get', `/pfpclient/pfpClientDocument/${id}`);
    if (res.success) {
        await dispatch({ type: t.FETCHED_DOCUMENT_BY_ID, payload: res.body });
    } else {
        await dispatch({ type: t.FETCHED_DOCUMENT_BY_ID, payload: res.body });
    }
}

export const getCouponById = (id) => async (dispatch) => {

    const res = await Api('get', `/pfpclient/coupon/${id}`);
    if (res.success) {
        await dispatch({ type: t.FETCHED_COUPON_BY_ID, payload: res.body });
    } else {
        await dispatch({ type: t.FETCHED_COUPON_BY_ID, payload: res.body });
    }
}

export const getCouponParamById = (id) => async (dispatch) => {

    const res = await Api('get', `/pfpclient/couponParam/${id}`);
    if (res.success) {
        await dispatch({ type: t.FETCHED_COUPON_PARAM_BY_ID, payload: res.body });
    } else {
        await dispatch({ type: t.FETCHED_COUPON_PARAM_BY_ID, payload: res.body });
    }
}


export const getCouponsParamList = () => async (dispatch) => {
    const res = await Api('get', `./pfpclient/couponParam/findAll`);
    if (res.success) {
        await dispatch({ type: t.FETCHED_PFP_COUPON_PARAM, payload: res.body });
    } else {
        await dispatch({ type: t.FETCHED_PFP_COUPON_PARAM, payload: res.body });
    }
}


export const getCommitmentsById = (id) => async (dispatch) => {
    const res = await Api('get', `/pfpclient/commitment/${id}`);
    if (res.success) {
        await dispatch({ type: t.FETCHED_COMMITMENT_BY_ID, payload: res.body });
    } else {
        await dispatch({ type: t.FETCHED_COMMITMENT_BY_ID, payload: res.body });
    }
}


