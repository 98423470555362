import t from "../../actions/PfpActions/types";
const initialState = {
    commitmentList: [],
    couponsList: [],
    couponsFilteredList: {
        content: []
    },
    couponParamFilteredList: {
        content: []
    },
    transactionsList: {
        content: []
    },
    transaction: {},
    allTransactions: [],
    coupon: {},
    couponParam: {},

    commitmentAllList: {
        content: []
    },
    couponsParamList: [],
    pfpbankStatementList: [],
    commitments: {},
    document: {},
    bankStatement: {}
}

export default (state = initialState, action) => {
    switch (action.type) {
        case t.FETCHED_PFP_COMMITMENTS:
            return {
                ...state,
                commitmentList: action.payload
            }
            break;
        case t.FETCHED_ALL_COMMITMENTS:
            return {
                ...state,
                commitmentAllList: action.payload
            }
            break;
        case t.FETCHED_ALL_COUPONS:
            return {
                ...state,
                couponsList: action.payload
            }
            break;
        case t.FETCHED_FILTERED_COUPONS:
            return {
                ...state,
                couponsFilteredList: action.payload
            }
            break;
        case t.FETCHED_FILTERED_COUPON_PARAM:
            return {
                ...state,
                couponParamFilteredList: action.payload
            }
            break;
        case t.FETCHED_ALL_TRANSACTIONS:
        case t.FETCHED_ALL_TRANSACTIONS:
            //console.log("Mechant Action ",action.payload);
            return {
                ...state,
                transactionsList: action.payload
            }
            break;
        case t.FETCHED_TRANSACTIONS:
            return {
                ...state,
                allTransactions: action.payload
            }
            break;
        case t.FETCHED_TRANSACTION_BY_ID:
            return {
                ...state,
                transaction: action.payload
            }
            break;
        case t.FETCHED_COUPON_BY_ID:
            return {
                ...state,
                coupon: action.payload
            }
            break;
        case t.FETCHED_COUPON_PARAM_BY_ID:
            return {
                ...state,
                couponParam: action.payload
            }
        case t.FETCHED_PFP_COUPON_PARAM:
            return {
                ...state,
                couponsParamList: action.payload
            }
            break;
        case t.FETCHED_COMMITMENT_BY_ID:
            return {
                ...state,
                commitments: action.payload
            }
            break;
        case t.FETCHED_DOCUMENT_BY_ID:
            return {
                ...state,
                document: action.payload
            }
            break;
        case t.FETCHED_ALL_PFP_BANK_STATEMENTS:
            return {
                ...state,
                pfpbankStatementList: action.payload
            }
            break;
        case t.FETCHED_PFPBANKSTATEMENT_BY_ID:
            return {
                ...state,
                bankStatement: action.payload
            }
            break;
        default:
            return state;
            break;
    }
}
