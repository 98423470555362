import t from "./types";
import { Api } from "../../services/httpService";


export const verifyOtp = (param) => async (dispatch) => {
    const res = await Api('POST', `/users/verifyOTP`, param, true);
    if (res) {
        await dispatch({ type: t.VERIFY_OTP, payload: res });
    }
}



