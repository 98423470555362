import t from "../../actions/MerchantActions/types";
const initialState = {
    merchantList: {
        content: []
    },
    allMerchantList: {
        content: []
    },
    partnersList: {
        content: []
    },
    notificationList: [],
    loginData: {},
    totalElement: 0,
    remittanceData: {
        content: []
    },
    investorList: [],
    investorDetails: {},
    merchantDetails: "",
    searchFilterData: {
        leadOwner: '',
        merchants: '',
        isSort: '',
        accountStatus: '',
        fromDate: '',
        toDate: '',
        merchantId: ''
    },
    verifyEmailData: {},
    verifyOtpData: {},
    changePswdData: {},
}

export default (state = initialState, action) => {
    switch (action.type) {
        case t.FETCHED_MERCHANT:
            //console.log("Mechant Action ",action.payload);
            return {
                ...state,
                merchantList: action.payload
            }
            break;
        case t.FETCHED_ALL_PARTNERS:
            //console.log("Mechant Action ",action.payload);
            return {
                ...state,
                partnersList: action.payload
            }
            break;
        case t.FETCHED_ALL_MERCHANT:
            //console.log("Mechant Action ",action.payload);
            return {
                ...state,
                allMerchantList: action.payload
            }
            break;
        case t.LOGIN:
            return {
                ...state,
                loginData: action.payload
            }
            break;
        case t.GET_NOTIFICATION_LIST:
            return {
                ...state,
                notificationList: action.payload.body?.content,
                totalElement: action.payload.body?.totalElements
            }
            break;
        case t.GET_REMITTANCE:
            return {
                ...state,
                remittanceData: action.payload.payments
            }
            break;
        case t.GET_INVESTOR_LIST:
            return {
                ...state,
                investorList: action?.payload?.body
            }
            break;
        case t.GET_INVESTOR_DETAILS:
            return {
                ...state,
                investorDetails: action?.payload?.body
            }
            break;
        case t.GET_MERCHANT_DETAILS:
            return {
                ...state,
                merchantDetails: action?.payload?.body
            }
            break;
        case t.SET_CUSTOM_FILTER:
            return {
                ...state,
                searchFilterData: {
                    leadOwner: action.payload?.leadOwner,
                    merchants: action.payload?.merchants,
                    isSort: action.payload?.isSort,
                    accountStatus: action.payload?.accountStatus,
                    fromDate: action.payload?.fromDate,
                    toDate: action.payload?.toDate,
                    merchantId: action.payload?.merchantId,
                    accountStatus: action.payload?.accountStatus,
                    isCountry: action.payload?.isCountry,
                }
            }
            break;
        case t.VERIFY_EMAIL:
            return {
                ...state,
                verifyEmailData: action.payload
            };
            break;
        case t.VERIFY_OTP:
            return {
                ...state,
                verifyOtpData: action.payload ? action.payload : {}
            }
        case t.CHANGE_PSWD:
            return {
                ...state,
                changePswdData: action.payload ? action.payload : {}
            }
        default:
            return state;
            break;
    }
}
