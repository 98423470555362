import t from "../../actions/PurchaseOrderActions/types";

const initialState = {
  purchaseOrderList: {
    content: [],
    purchaseOrder: []
  },
  merchantPurchaseOrderList: {
    wallet: []
  },
  allPurchaseOrderList: {
    content: [],
    purchaseOrder: []
  },
  purchaseOrderDetails: {},
  purchaseOrderInvoicesDetails: {},
  addedPurchaseOrderDetails: {}
};

export default (state = initialState, action) => {
  switch (action.type) {
    case t.FETCHED_PURCHASE_ORDER:
      // let allPurchaseOrderList = action.payload
      return {
        ...state,
        purchaseOrderList: Object.assign({}, action.payload),
        allPurchaseOrderList: Object.assign({}, action.payload),
      };

    case t.FETCHED_MERCHANT_PURCHASE_ORDER:
      let oldPurchaseOrderList = state.purchaseOrderList;

      oldPurchaseOrderList["content"] = action.payload ? action.payload.purchaseOrder ? action.payload.purchaseOrder : action.payload.content : [];
      return {
        ...state,
        purchaseOrderList: oldPurchaseOrderList,
      };

    case t.FETCHED_PURCHASE_ORDER_DETAILS:
      //console.log("details", action.payload);
      state.purchaseOrderList["content"] = [action.payload.purchaseOrder];
      return {
        ...state,
        purchaseOrderDetails: action.payload
      }

    case t.FETCHED_PURCHASE_ORDER_INVOICES_DETAILS:
      state.purchaseOrderList["content"] = [action.payload.purchaseOrderInvoices];
      return {
        ...state,
        purchaseOrderInvoicesDetails: action.payload
      }

    case t.ADD_PURCHASE_ORDER:
      return {
        ...state,
        addedPurchaseOrderDetails: action.payload
      }

    default:
      return state;
  }
};
