import t from "./types";
import { Api } from "../../services/httpService";

export const getPartnersList = () => async (dispatch) => {
    const res = await Api('get', `/fwf/partner/findAll`);
    if (res.success) {
        await dispatch({ type: t.FETCHED_ALL_PARTNERS, payload: res.body });
    } else {
        if(res.status == 401){
            window.location = '/admin/page/UnAuthorized';
        }
        else{
            console.log("error----", res);
            await dispatch({ type: t.FETCHED_ALL_PARTNERS, payload: res.body });
        }
    }
}

export const getPartner = (id) => async (dispatch) => {
    const res = await Api('get', `/fwf/partner/${id}`);
    if (res.success) {
        await dispatch({ type: t.FETCH_PARTNER, payload: res.body });
    } else {
        await dispatch({ type: t.FETCH_PARTNER, payload: res.body });
    }
}

export const getPartnerBanks = () => async (dispatch) => {
    const res = await Api('get', `/fwf/bank/findAll`);
    if (res.success) {
        await dispatch({ type: t.FETCH_PARTNER_BANKS, payload: res.body });
    } else {
        await dispatch({ type: t.FETCH_PARTNER_BANKS, payload: res.body });
    }
}


export const getPartnersFactParams = () => async (dispatch) => {
    const res = await Api('get', `/fwf/param/findAll`);
    if (res.success) {
        await dispatch({ type: t.FETCHED_ALL_PARTNERS_FACT_PARAMS, payload: res.body });
    } else {
        if(res.status == 401){
            window.location = '/admin/page/UnAuthorized';
        }
        else{
            console.log("error----", res);
            await dispatch({ type: t.FETCHED_ALL_PARTNERS_FACT_PARAMS, payload: res.body });
        }
    }
}

export const getFactParam = (id) => async (dispatch) => {
    const res = await Api('get', `/fwf/param/${id}`);
    if (res.success) {
        await dispatch({ type: t.FETCH_PARTNER_FACT_PARAM, payload: res.body });
    } else {
        await dispatch({ type: t.FETCH_PARTNER_FACT_PARAM, payload: res.body });
    }
}




