import t from "./types";
import { Api } from '../../services/httpService';

export const getDashBoaradData = (param) => async dispatch => {
    try {
        let response = "";
        if (param.merchantId) {
            response = await Api('get', `/dashboard/listing?bpTypeDesc=${param.bpType}&accountStatus=${param.accountStatus}&page=${param.page}&size=${param.size}&merchantId=${param.merchantId}`);
        } else {
            response = await Api('get', `/dashboard/listing?bpTypeDesc=${param.bpType}&accountStatus=${param.accountStatus}&page=${param.page}&size=${param.size}`);
        }

        if (response.success) {
            dispatch({ type: t.FETCHED_ALL_DASHBOARD_DATA, payload: response.body, data: param.bpType });
        } else {
            if(response.status == 401){
                window.location = '/admin/page/UnAuthorized';
            }
            else{
                console.log("error----", response);
                dispatch({ type: t.FETCHED_ALL_DASHBOARD_DATA_ERROR, payload: response.message });
            }
        }
        
        dispatch({ type: t.FETCHED_ALL_DASHBOARD_DATA_LOADING, payload: false });

    } catch (e) {
        dispatch({ type: t.FETCHED_ALL_DASHBOARD_DATA_ERROR, payload: e });
        dispatch({ type: t.FETCHED_ALL_DASHBOARD_DATA_LOADING, payload: false });

    }
};


export const getMerchantBuyerReport = (merchantId, buyerPlatformId, periodCode, buyerPlatformName) => async (dispatch) => {
    try {
        const response = await Api('get', `/performance/${buyerPlatformName}/?merchantId=${merchantId}&buyerPlatFormId=${buyerPlatformId}&periodCode=${periodCode}`);
        // console.log("response======", response);
        if (response.success) {
            dispatch({ type: t.FETCHED_MERCHANT_BUYERPLATFORM_REPORT, payload: response.body });
        } else {
            console.log("error", response);
        }
    } catch (error) {
        console.log("error", error);
    }
}

// Get Currency List 

export const getCurrencyList = () => async (dispatch) => {
    try {
        const res = await Api('get', `/common/currenctList`);

        if (res.success) {
            dispatch({ type: t.GET_CURRENCY_LIST, payload: res.body });
        }
    } catch (error) {
        console.log("error", error);
    }
}




