export default {
    GET_MENU_LIST: "GET_MENU_LIST",
    GET_ROLE_LIST: "GET_ROLE_LIST",
    GET_ROLE_DETAILS: "GET_ROLE_DETAILS",
    ADD_EDIT_ROLE: "ADD_EDIT_ROLE",
    GET_MENU_ROLE: "GET_MENU_ROLE",
    CREATE_USER: "CREATE_USER",
    GET_USER_BY_ROLE: "GET_USER_BY_ROLE",
    GET_ALL_USERS: "GET_ALL_USERS",
    GET_USER_MENU_ROLE: "GET_USER_MENU_ROLE"
}