export default {
    FETCHED_PFP_COMMITMENTS: "FETCHED_PFP_COMMITMENTS",
    FETCHED_ALL_COUPONS: "FETCHED_ALL_COUPONS",
    FETCHED_ALL_TRANSACTIONS: "FETCHED_ALL_TRANSACTIONS",
    FETCHED_ALL_PFP_BANK_STATEMENTS: "FETCHED_ALL_PFP_BANK_STATEMENTS",
    FETCHED_ALL_COMMITMENTS:"FETCHED_ALL_COMMITMENTS",
    FETCHED_PFP_COUPON_PARAM:"FETCHED_PFP_COUPON_PARAM",
    FETCHED_FILTERED_COUPONS: "FETCHED_FILTERED_COUPONS",
    FETCHED_FILTERED_COUPON_PARAM: "FETCHED_FILTERED_COUPON_PARAM",
    FETCHED_TRANSACTIONS: "FETCHED_TRANSACTIONS",
    FETCHED_TRANSACTION_BY_ID: "FETCHED_TRANSACTION_BY_ID",
    FETCHED_PFPBANKSTATEMENT_BY_ID: "FETCHED_PFPBANKSTATEMENT_BY_ID",
    FETCHED_DOCUMENT_BY_ID: "FETCHED_DOCUMENT_BY_ID",
    FETCHED_COUPON_BY_ID: "FETCHED_COUPON_BY_ID",
    FETCHED_COUPON_PARAM_BY_ID: "FETCHED_COUPON_PARAM_BY_ID",
    FETCHED_COMMITMENT_BY_ID:"FETCHED_COMMITMENT_BY_ID"
}