import t from "../../actions/ClientMonitorActions/types";

const initialState = {
  dashboardList: [],
  dashboardListError: undefined,
  dashboardListLoading: false,
  merchantBuyerReport: [],
  currencyList: [],
  dashboardDataList: [],
  totalPages: ""
}
export default (state = initialState, action) => {
  switch (action.type) {
    case t.FETCHED_ALL_DASHBOARD_DATA:

      let traditionalData = [];
      let ecomData = [];
      let workingData = [];

      if (action.data == "Traditional") {

        return {
          ...state,
          traditionalData: action?.payload?.content ?? [],
          totalPages: action?.payload?.totalPages ?? 0,
        }
      }
      if (action.data == "E-Commerce") {
        return {
          ...state,
          ecomData: action?.payload?.content ?? [],
          totalPages: action?.payload?.totalPages ?? 0,
        }
      }



      if (action.data == "Working Capital") {
        return {
          ...state,
          workingData: action?.payload?.content ?? [],
          totalPages: action?.payload?.totalPages ?? 0,
        }
      }
      break;


    case t.FETCHED_ALL_DASHBOARD_DATA_LOADING:
      return {
        ...state, dashboardListLoading: action.payload
      };
      break;
    case t.FETCHED_ALL_DASHBOARD_DATA_ERROR:
      return {
        ...state, dashboardListError: action.payload
      };
      break;
    case t.FETCHED_MERCHANT_BUYERPLATFORM_REPORT:
      return {
        ...state,
        merchantBuyerReport: action.payload
      }
      break;
    case t.GET_CURRENCY_LIST:
      return {
        ...state,
        currencyList: action.payload
      }
      break;
    default:
      return state;
  }
}
