import t from "./types";
import { Api } from "../../services/httpService";

/* GET ALL NOTIFICATION TEMPLETE LIST */

export const getEmailTemplates = () => async (dispatch) => {
    dispatch({ type: t.FETCHED_EMAIL_TEMPLETE_LOADING, payload: true });
    const res = await Api("get", `/emailTemplate/getTemplates`);
    if (res.success) {
        await dispatch({ type: t.FETCHED_EMAIL_TEMPLETE, payload: res.body.content ? res.body.content : [] });
    } else {
        if(res.status == 401){
            window.location = '/admin/page/UnAuthorized';
            await dispatch({ type: t.FETCHED_EMAIL_TEMPLETE_ERROR, payload: res });
        }
        else{
            console.log("error----", res);
            await dispatch({ type: t.FETCHED_EMAIL_TEMPLETE_ERROR, payload: res });
        }
    };
    dispatch({ type: t.FETCHED_EMAIL_TEMPLETE_LOADING, payload: false });
};

/* UPDATE NOTIFICATION TEMPLATE */

export const emailTempleteEntry = (params) => async (dispatch) => {
    dispatch({ type: t.EMAIL_TEMPLETE_ENTRY_LOADING, payload: true });
    const res = await Api("post", `/emailTemplate/templateEntry`, params);
    if (res.success) {
        await dispatch({ type: t.EMAIL_TEMPLETE_ENTRY, payload: res.body });
    } else {
        await dispatch({ type: t.EMAIL_TEMPLETE_ENTRY_ERROR, payload: res });
    };
    dispatch({ type: t.FETCHED_EMAIL_TEMPLETE_LOADING, payload: false });
};
