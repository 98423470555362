import t from "../../actions/PermissionActions/types";


const initialState = {
    menuList: [],
    roleList: [],
    roleDetails: {},
    addEditRoleDetails: {},
    menuRole: [],
    createUserData: {},
    roleUsers: [],
    userList: [],
    userRoleMenuList: []
}


export default (state = initialState, action) => {
    switch (action.type) {
        case t.GET_MENU_LIST:
            return {
                ...state,
                menuList: action.payload.body
            }
            break;
        case t.GET_ROLE_LIST:
            return {
                ...state,
                roleList: action.payload.body
            }
            break;
        case t.GET_ROLE_DETAILS:
            return {
                ...state,
                roleDetails: action.payload.body
            }
            break;
        case t.ADD_EDIT_ROLE:
            return {
                ...state,
                addEditRoleDetails: action.payload.body
            }
            break;
        case t.GET_MENU_ROLE:
            return {
                ...state,
                menuRole: action.payload.body
            }
            break;
        case t.CREATE_USER:
            return {
                ...state,
                createUserData: action.payload
            }
            break;
        case t.GET_USER_BY_ROLE:
            return {
                ...state,
                roleUsers: action.payload.body
            }
            break;
        case t.GET_ALL_USERS:
            return {
                ...state,
                userList: action.payload.body
            }
            break;
        case t.GET_USER_MENU_ROLE:
            return {
                ...state,
                userRoleMenuList: action.payload.body
            }
            break;
        default:
            return state;
            break;
    }
}
