import React from 'react';
import Header from './Header/Header';
import SideBar from './Sidebar/SideBar';

const Layout = () => {
    return(
        <div>           
            
            <SideBar />  
            <Header />          
        </div>
        
    )
}

export default Layout;