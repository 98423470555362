import t from "../../actions/TraditionalFactoryActions/types";

const intialState = {
    traditionalData: {
        content: []
    },
    merchantTradionalList: {
        pending: []
    },
    traditionalFactoryDetails: {}
}

export default (state = intialState, action) => {
    switch (action.type) {
        case t.FETCHED_TRADITIONAL_FACTORY:
            return {
                ...state,
                traditionalData: action.payload
            }
            break;
        case t.FETCHED_TRADITIONAL_FACTORY_DETAILS:
            return {
                ...state,
                traditionalFactoryDetails: action.payload.factParam
            }
            break;
        case t.FETCHED_TRADIONAL_MERCHANT_PENDING:
            let oldTradionalList = state.traditionalData;

            oldTradionalList["content"] = action.payload ? action.payload.factParam ? action.payload.factParam : action.payload.content : [];
            return {
                ...state,
                traditionalData: oldTradionalList
            }
        default:
            return state;
            break;
    }
}



