export default {
    FETCHED_CMS_PAGE: 'FETCHED_CMS_PAGE',
    FETCHED_CMS_PAGE_SINGLE: 'FETCHED_CMS_PAGE_SINGLE',
    FETCHED_CMS_PAGE_ERROR: 'FETCHED_CMS_PAGE_ERROR',
    FETCHED_CMS_PAGE_LOADING: 'FETCHED_CMS_PAGE_LOADING',
    
    CMS_PAGE_ENTRY: "CMS_PAGE_ENTRY",
    CMS_PAGE_ENTRY_ERROR: "CMS_PAGE_ENTRY_ERROR",
    CMS_PAGE_ENTRY_LOADING: "CMS_PAGE_ENTRY_LOADING",

}