import t from '../../actions/CMSActions/types';

const initialState = {
    cmsPage: [],
    cmsPageError: undefined,
    cmsPageLoading: false,

    cmsPageEntry: undefined,
    cmsPageEntryError: undefined,
    cmsPageEntryLoading: false,
    cmsPageSingle: undefined
};
export default (state = initialState, action) => {
    switch (action.type) {
        case t.FETCHED_CMS_PAGE:
            return {
                ...state,
                cmsPage: action?.payload?.content?.length > 0 ? action?.payload?.content : [],
            };
        case t.FETCHED_CMS_PAGE_SINGLE:
            //console.log("FETCHED_CMS_PAGE", action.payload)
            return {
                ...state,
                cmsPageSingle: action.payload ? action.payload : {},
            };
        case t.FETCHED_CMS_PAGE_ERROR:
            return {
                ...state,
                cmsPageError: action.payload,
            };

        case t.FETCHED_CMS_PAGE_LOADING:
            return {
                ...state,
                cmsPageLoading: action.payload,
            };

        case t.CMS_PAGE_ENTRY:
            return {
                ...state,
                cmsPageEntry: action.payload,
            };
        case t.CMS_PAGE_ENTRY_ERROR:
            return {
                ...state,
                cmsPageEntryError: action.payload,
            };

        case t.CMS_PAGE_ENTRY_LOADING:
            return {
                ...state,
                cmsPageEntryLoading: action.payload,
            };

        default:
            return state;
    }
};
