import t from "./types";
import { Api } from "../../services/httpService";

export const getTraditionalFactory = () => async (dispatch) => {
    const res = await Api('get', `/factparam`);
    if (res.success) {
        await dispatch({ type: t.FETCHED_TRADITIONAL_FACTORY, payload: res.body });
    }
}


// add traditional 
export const addTraditionalFactory = (requestParam) => async (dispatch) => {
    //const res = await Api('post', `/factparam/entry?factParamOrderNum=1&dailyFactFeePercent=${requestParam.dailyFactoringFee}&underUtilizationFeePercent=${requestParam.utilizationFee}&underUtilizationPercentThreshold=${requestParam.utilizationThreshold}&dailyOverdueFeePercent=${requestParam.dailyOverDue}&advanceLimit=${requestParam.advanceLimit}&isActive=${requestParam.isActive}&merchantId=${requestParam.merchantId}`);
    const res = await Api('post', `/factparam/entry`, requestParam);
}



// get traditional factory 

export const getTraditionalFactoryDetails = (traditionalFactoryId) => async (dispatch) => {
    const res = await Api('get', `/factparam/${traditionalFactoryId}`);
    if (res.success) {
        await dispatch({ type: t.FETCHED_TRADITIONAL_FACTORY_DETAILS, payload: res.body });
    } else {
        await dispatch({ type: t.FETCHED_TRADITIONAL_FACTORY_DETAILS, payload: res.body });
    }
}

// update Traditional
export const updateTraditionalFactory = (requestParam) => async (dispatch) => {
    //const res = await Api('post', `/factparam/entry?factParamOrderNum=1&dailyFactFeePercent=${requestParam.dailyFactoringFee}&underUtilizationFeePercent=${requestParam.utilizationFee}&underUtilizationPercentThreshold=${requestParam.utilizationThreshold}&dailyOverdueFeePercent=${requestParam.dailyOverDue}&advanceLimit=${requestParam.advanceLimit}&isActive=${requestParam.isActive}&merchantId=${requestParam.merchantId}&id=${requestParam.id}`);
    const res = await Api('post', `/factparam/entry`, requestParam);
}


export const getPendingTradionalMerchant = (merchantId, buyerPlatformId, isInvoice = false) => async (dispatch) => {
    if (isInvoice == true && merchantId) {
        var res = await Api('get', `/factparam/${merchantId}/${buyerPlatformId}`);
    } else if (isInvoice == true && !merchantId) {
        var res = await Api('get', `/factparam?buyerPlatformsId=${buyerPlatformId}`);
    } else {
        var res = await Api('get', `/factparam/${merchantId}`);
    }
    await dispatch({ type: t.FETCHED_TRADIONAL_MERCHANT_PENDING, payload: res.body });
}



