import React, { Component } from 'react';
import { connect } from 'react-redux';
import Modal from 'react-bootstrap/Modal';
import { MerchantActions } from "../../actions";
import InfiniteScroll from 'react-infinite-scroll-component';

class Notifications extends Component {
    id = window.localStorage.getItem("_id");
    constructor(props) {
        super(props);
        this.state = {
            showModal: false,
            notificationData: [],
            funderReceivedAmount: 0,
            unReadNotifications: [],
            sortedData: [],
            hasMore: true
        }
    }

    componentDidMount() {
        let param = {
            merchantId: window.localStorage.getItem("_id"),
            size: 20,
            page: 0
        }
        this.props.getNotifications(param).then(() => {
            if (this.props.notificationList.length == 0) {
                this.setState({
                    hasMore: false
                })
            } else {
                this.setState({
                    notificationData: this.state.notificationData.concat(this.props.notificationList)
                })
            }

        });
    }

    updateNotification = (id) => {
        const params = {
            "merchantId": this.id,
            "flag": 1,
            "id": id,
        }
    }

    fetchMoreData = () => {
        // setTimeout(() => {
        if (this.state.notificationData.length >= this.props.totalElement) {
            this.setState({ hasMore: false });
            return;
        }
        let page = this.state.notificationData.length / 20;
        let param = {
            merchantId: window.localStorage.getItem("_id"),
            size: 20,
            page: page
        }
        this.props.getNotifications(param).then(() => {
            this.setState({
                notificationData: this.state.notificationData.concat(this.props.notificationList)
            })
        });
        // }, 1500);
    }


    render() {
        const { notificationData } = this.state;
        return (
            <Modal
                show={this.props.openModal}
                onHide={() => this.props.closeModal()}
                className="ModalDemo action_msg"
                dialogClassName="mdlDir"
                aria-labelledby="example-custom-modal-styling-title">
                <Modal.Header className="border-0 notification-title" closeButton>Notifications</Modal.Header>
                <Modal.Body className="overflow-auto vh-100" id="scrollableDiv">
                    <InfiniteScroll
                        dataLength={notificationData.length}
                        next={() => this.fetchMoreData()}
                        className="pl-3 pr-3 notifiBox"
                        hasMore={this.state.hasMore}
                        loader={<h4>Loading...</h4>}
                        scrollableTarget="scrollableDiv"
                        endMessage={<h4>No more data</h4>}
                    >
                        {notificationData.map((notification, index) => {
                            return (
                                <div key={index}><span>{notification.id}</span> <p>{notification.message}</p></div>
                            )
                        })}
                    </InfiniteScroll>
                </Modal.Body>
            </Modal >
        )
    }
}

const mapStateToProps = ({ MerchantListReducer }) => {
    return {
        notificationList: MerchantListReducer.notificationList,
        totalElement: MerchantListReducer.totalElement
    }
}

const mapDispatchToProps = {
    getNotifications: MerchantActions.getNotifications
}
export default connect(mapStateToProps, mapDispatchToProps)(Notifications)


