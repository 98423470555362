import t from "../../actions/InvoicesAction/types";

const initialState = {
  invoicesList: {
    content: [],
    invoice: []
  },
  merchantInvoiceList: {
    invoice: []
  },
  allInvoiceList: {
    content: [],
    invoice: []
  },
  invoiceDetails: {},
  createInvoice: {},
  invoicePdf: {},
  invoiceId: {},
  excelSheetUpload: null,
  deletedInvoicesList: []
};

export default (state = initialState, action) => {
  switch (action.type) {
    case t.FETCHED_INVOICES:
      let updatedList = [];
      updatedList["content"] = action.payload && action.payload.content ? action.payload.content : action.payload
      return {
        ...state,
        invoicesList: Object.assign({}, state.invoicesList, updatedList),
        allInvoiceList: Object.assign({}, state.allInvoicesList, updatedList)
      };

    case t.FETCHED_MERCHANT_INVOICES:
      let oldInvoiceList = state.invoicesList;
      oldInvoiceList["content"] = action.payload && action.payload.Invoices ? action.payload.Invoices : action.payload;

      return {
        ...state,
        invoicesList: oldInvoiceList,

      };

    case t.FETCHED_INVOICES_DETAILS:
      state.invoicesList["content"] = action.payload ? [action.payload.Invoices] : [];
      return {
        ...state,
        invoiceDetails: action.payload,
      };

    case t.FILTER_INVOICES_LIST:
      state.invoicesList["content"].push(action.payload ? action.payload.Invoices : null)
      return {
        ...state
      };

    case t.CLEAR_INVOICE_LIST:
      let list = state.invoicesList
      list["content"] = [];
      return { ...state, invoicesList: list }

    case t.CREATE_INVOICE:
      return {
        ...state,
        createInvoice: action.payload.body,
      }

    case t.INVOICE_PDF:

      console.log("INVOICE_PDF", action);

      return {
        ...state,
        invoicePdf: action.payload,
        // invoiceId: action.invoiceId
      }
    case t.INVOICE_ID:
      return {
        ...state,
        invoicePdf: action.payload,
        invoiceId: action.invoiceId
      }
    case t.INVOICES_EXCEL_SHEET:
      return {
        ...state,
        excelSheetUpload: action.payload.message
      };
    case t.DELETE_INVOICES_LIST:
      return {
        ...state,
        deletedInvoicesList: action.payload
      }
    case t.RESET_INVOICE_ID:
      return {
        ...state,
        invoiceId: ''
      }

    default:
      return state;
  }
};
