import t from '../../actions/NotificationAction/types';

const initialState = {
    notificationTemplate: undefined,
    notificationTemplateError: undefined,
    notificationTemplateLoading: false,

    notificationTemplateEntry: undefined,
    notificationTemplateEntryError: undefined,
    notificationTemplateEntryLoading: false
};
export default (state = initialState, action) => {
    switch (action.type) {
        case t.FETCHED_NOTIFICATION_TEMPLETE:
            //console.log("FETCHED_NOTIFICATION_TEMPLETE", action.payload)
            return {
                ...state,
                notificationTemplate: action.payload,
            };
        case t.FETCHED_NOTIFICATION_TEMPLETE_ERROR:
            return {
                ...state,
                notificationTemplateError: action.payload,
            };

        case t.FETCHED_NOTIFICATION_TEMPLETE_LOADING:
            return {
                ...state,
                notificationTemplateLoading: action.payload,
            };

        case t.NOTIFICATION_TEMPLETE_ENTRY:
            return {
                ...state,
                notificationTemplateEntry: action.payload,
            };
        case t.NOTIFICATION_TEMPLETE_ENTRY_ERROR:
            return {
                ...state,
                notificationTemplateEntryError: action.payload,
            };

        case t.NOTIFICATION_TEMPLETE_ENTRY_LOADING:
            return {
                ...state,
                notificationTemplateEntryLoading: action.payload,
            };



        default:
            return state;
    }
};
