import { Api } from "./httpService";
import moment from "moment";

export const checkTokenExpired = async () => {

    const tokenGenerateTime = localStorage.getItem("setupTime");
    const loginDateTime = localStorage.getItem("loginDateTime");
    const currentTimeVal    = new Date().getTime();
    const msDiff = currentTimeVal - loginDateTime; /* calculate diffrance time  */
    const diff = Math.floor((msDiff/1000)/60); /* calculate minuts after login  */

    if(!localStorage.getItem("_token") || localStorage.getItem("_token") === "" || diff > tokenGenerateTime){
        const res = await  Api('post','/users/logout');
        if (res) {
            localStorage.clear();
            localStorage.setItem('isTimeSessionOutAD', true)
            window.location = '/';
        }
    }
}