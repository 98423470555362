export default {
    FETCHED_MERCHANT: "FETCHED_MERCHANT",
    FETCHED_ALL_MERCHANT: "FETCHED_ALL_MERCHANT",
    LOGIN: "LOGIN",
    GET_NOTIFICATION_LIST: "GET_NOTIFICATION_LIST",
    GET_REMITTANCE: "GET_REMITTANCE",
    GET_INVESTOR_LIST: "GET_INVESTOR_LIST",
    GET_INVESTOR_DETAILS: "GET_INVESTOR_DETAILS",
    GET_MERCHANT_DETAILS: "GET_MERCHANT_DETAILS",
    FETCHED_ALL_PARTNERS: "FETCHED_ALL_PARTNERS",
    SET_CUSTOM_FILTER:"SET_CUSTOM_FILTER",
    VERIFY_EMAIL: "VERIFY_EMAIL",
    VERIFY_OTP: "VERIFY_OTP",
    CHANGE_PSWD: "CHANGE_PSWD",
}