import t from "./types";
import { Api } from "../../services/httpService";

// Get all wallet List

export const getWallet = () => async (dispatch) => {
  const res = await Api("get", `/wallet`);
  if (res.success) {
    await dispatch({ type: t.FETCHED_WALLET, payload: res.body });
  }
};

// Get single Merchant list
export const getMerchantWallet = (merchantId, buyerPlatformId, isBoth = false) => async (
  dispatch
) => {
  let res = []
  if (isBoth && merchantId) {
    res = await Api("get", `/wallet/${merchantId}/${buyerPlatformId}`);

  } else if (isBoth && !merchantId) {
    res = await Api("get", `/wallet?buyerPlatformsId=${buyerPlatformId}`);
  } else {
    res = await Api("get", `/wallet/${merchantId}`);
  }

  await dispatch({ type: t.FETCHED_MERCHANT_WALLET, payload: res.body });
  // if (res.success) {
  //     await dispatch({ type: t.FETCHED_MERCHANT_WALLET, payload: res.body });
  // } else {
  //     await dispatch({ type: t.FETCHED_MERCHANT_WALLET, payload: res.body });
  // }
};

// Add Wallet record

export const addWallet = (requestParam) => async (dispatch) => {
  // const res = await Api('post', `/wallet/entry?merchantId=${requestParam.merchantId}&transactionType=${requestParam.transactionType}&transactionRemarks=${requestParam.transactionRemarks}&amount=${requestParam.amount}&balance=${requestParam.balance}`);
  const res = await Api("post", `/wallet/entry`, requestParam);

  let transferReceiptPdfFile = requestParam["transferReceiptPdfFile"];
  if (res.success === true && transferReceiptPdfFile) {
    let form_data = new FormData();
    form_data.append(
      "rceiptFile",
      transferReceiptPdfFile,
      transferReceiptPdfFile.name
    );
    form_data.append("walletId", res.body.id);
    dispatch(transferReceiptFile(form_data));
  }
};

// Edit Wallet record

export const getWalletDetails = (walletId) => async (dispatch) => {
  const res = await Api("get", `/wallet/${walletId}`);
  if (res.success) {
    await dispatch({ type: t.FETCHED_WALLET_DETAILS, payload: res.body });
  } else {
    await dispatch({ type: t.FETCHED_WALLET_DETAILS, payload: res.body });
  }
};

//Update Wallet Record
export const updateWallet = (requestParam) => async (dispatch) => {
  const res = await Api("post", `/wallet/entry`, requestParam);

  let transferReceiptPdfFile = requestParam["transferReceiptPdfFile"];
  if (res.success === true && transferReceiptPdfFile) {
    let form_data = new FormData();
    form_data.append(
      "rceiptFile",
      transferReceiptPdfFile,
      transferReceiptPdfFile.name
    );
    form_data.append("walletId", res.body.id);
    dispatch(transferReceiptFile(form_data));
  }
};

export const transferReceiptFile = (params) => async (dispatch) => {
  const res = await Api("post", `/wallet/transferReceipt`, params, true);
  if (res) {
    await dispatch({ type: t.RECEIPTFILE, payload: res });
  }
};
