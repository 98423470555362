import t from "./types";
import { Api } from '../../services/httpService';



// Get all INVOICES List 
export const getInvoices = (Status) => async (dispatch) => {
    const res = Status ? await Api('get', `/invoices?invoiceStatus=${Status}`) : await Api('get', `/invoices`);
    // console.log(res)
    if (res.success) {
        await dispatch({ type: t.FETCHED_INVOICES, payload: res.body });
    }
};
export const getSearchInvoices = (invoiceNumber, buyerPlatformId, merchantId) => async (dispatch) => {
    let url = "";
    if(invoiceNumber){
        if (buyerPlatformId && merchantId) {
            url = "&buyerPlatformsId=" + buyerPlatformId + "&merchantId=" + merchantId + "&invoiceStatusNot=Paid";
        }
        const res = await Api('get', `/invoices?invoiceNumber=${invoiceNumber}${url}`)
        if (res.success) {
            await dispatch({ type: t.FETCHED_INVOICES, payload: res.body });
        }
    }
    else{
        if (buyerPlatformId && merchantId) {
            url = "&buyerPlatformsId=" + buyerPlatformId + "&merchantId=" + merchantId + "&invoiceStatusNot=Paid";
        }
        const res = await Api('get', `/invoices?${url}`)
        if (res.success) {
            await dispatch({ type: t.FETCHED_INVOICES, payload: res.body });
        }
    }

    // const res = await Api('get', `/invoices?invoiceNumber=${invoiceNumber}`)
    // console.log(res)
    
}

// Get single Merchant list
export const getMerchantInvoices = (merchantId, buyerPlatformId, isInvoice = false) => async (dispatch) => {
    // var res = ''
    if (isInvoice == true && merchantId) {
        var res = await Api('get', `/invoices/${merchantId}/${buyerPlatformId}`);
    } else if (isInvoice == true && !merchantId) {
        var res = await Api('get', `/invoices?buyerPlatformsId=${buyerPlatformId}`);
    } else {
        var res = await Api('get', `/invoices/${merchantId}/`);
    }
    if (res.success) {
        await dispatch({ type: t.FETCHED_MERCHANT_INVOICES, payload: res.body });
    } else {
        await dispatch({ type: t.FETCHED_MERCHANT_INVOICES, payload: res.body });
    }
}

// Add INVOICES record
export const addInvoices = (requestParam,isModelAdded = false) => async (dispatch) => {
    // const res = await Api('post', `/INVOICES/entry?merchantId=${requestParam.merchantId}&transactionType=${requestParam.transactionType}&transactionRemarks=${requestParam.transactionRemarks}&amount=${requestParam.amount}&balance=${requestParam.balance}`);
    const res = await Api('post', `/invoices/entry`, requestParam);
    let invoicePDF = requestParam['invoicePDFFile']
    if (res.success === true && invoicePDF) {
        let form_data = new FormData();
        form_data.append('file', requestParam.invoicePDFFile, requestParam.invoicePDFFile.name)
        form_data.append('invoicesId', res.body.id)
        form_data.append('buyerPlatformsId', requestParam.buyerPlatformId)
        form_data.append("merchantId", requestParam.merchantId);
        dispatch(invoicePdf(form_data, res.body.id,isModelAdded));
    } else {
        dispatch(invoicePdf(null, res.body.id,isModelAdded));
    }
}

// Edit INVOICES record 
export const getInvoicesDetails = (invoiceId) => async (dispatch) => {
    const res = await Api('get', `/invoices/${invoiceId}`);
    if (res.success) {
        await dispatch({ type: t.FETCHED_INVOICES_DETAILS, payload: res.body });
    } else {
        await dispatch({ type: t.FETCHED_INVOICES_DETAILS, payload: res.body });
    }
};
export const getInvoicesFilterInvoices = (invoiceId) => async (dispatch) => {
    const res = await Api('get', `/invoices/${invoiceId}`);
    if (res.success) {
        await dispatch({ type: t.FILTER_INVOICES_LIST, payload: res.body });
    } else {
        await dispatch({ type: t.FILTER_INVOICES_LIST, payload: res.body });
    }
};

//Update INVOICES Record
export const updateInvoices = (requestParam) => async (dispatch) => {
    // const res = await Api('post', `/invoices/entry?merchantId=${requestParam.merchantId}&transactionType=${requestParam.transactionType}&transactionRemarks=${requestParam.transactionRemarks}&amount=${requestParam.amount}&balance=${requestParam.balance}&id=${requestParam.id}`);
    const res = await Api('post', `/invoices/entry`, requestParam);
    let invoicePDF = requestParam['invoicePDFFile']
    if (res.success === true && invoicePDF) {
        let form_data = new FormData();
        form_data.append('file', requestParam.invoicePDFFile, requestParam.invoicePDFFile.name)
        form_data.append('invoicesId', res.body.id)
        form_data.append('buyerPlatformsId', requestParam.buyerPlatformId)
        form_data.append("merchantId", requestParam.merchantId);
       
        dispatch(invoicePdf(form_data, res.body.id));
    }
}

export const invoicePdf = (params, invoiceId,appendInSettelments = false) => async (dispatch) => {
    if (params) {
        const res = await Api('post', `/invoices/pdf`, params, true);
        if (res) {
            appendInSettelments ?
                dispatch({ type: t.INVOICE_ID,payload: res, invoiceId: invoiceId })
            :
                dispatch({ type: t.INVOICE_PDF, payload: res, invoiceId: invoiceId });
        }
    } else {
        appendInSettelments ?
            dispatch({ type: t.INVOICE_ID,payload: {}, invoiceId: invoiceId })
        :
            dispatch({ type: t.INVOICE_PDF, payload: {}, invoiceId: invoiceId });
    }   
};

export const uploadExcelSheet = (params) => async (dispatch) => {
    const res = await Api('post', `/invoices/excelInvoiceUpload`, params, true);
    if (res) {
        await dispatch({ type: t.INVOICES_EXCEL_SHEET, payload: res });
        dispatch(getInvoices());
    }
};

export const clearInvoiceList = () => ({
    type: t.CLEAR_INVOICE_LIST,
    payload: []
})
export const resendEmail = (merchantId, invoiceId) => async (dispatch) => {
    const res = await Api('get', `/invoices?verificationResend/merchantId=${merchantId}&invoiceId=${invoiceId}`)
    if (res.success) {
        await dispatch({ type: t.RESEND_EMAIL, payload: res });
    }
}
export const restoreDeleteInvoice = (params) => async (dispatch) => {
    const res = await Api('post', `/invoices/deleted/restore/merchantId=${params.merchantId}&deletedId=${params.deletedId}`)
    if (res.success) {
        await dispatch({ type: t.RESTORE_DELETED_INVOICE, payload: res });
    }
}
export const deleteInvoice = (params) => async (dispatch) => {
    const res = await Api('post', `/invoices/deleteInvoice?id=${params.id}`)
    if (res.success) {
        await dispatch({ type: t.DELETE_INVOICE, payload: res });
    }
}
export const deleteInvoicesList = () => async (dispatch) => {
    const res = await Api('get', `/invoices/deleted/findAll`)
    if (res.success) {
        await dispatch({ type: t.DELETE_INVOICES_LIST, payload: res.body });
    }
}

export const resetInvoiceId = () => async dispatch => {
    dispatch({type:t.RESET_INVOICE_ID})
}