import React, { useEffect, useState } from 'react';
import './Header.css';
import Notifications from '../../Model/Notifications'
import { checkTokenExpired } from '../../../services/utilService';
import { Link, useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSignOutAlt } from "@fortawesome/free-solid-svg-icons";
//import {FunderLogo} from '../assests/FunderLogo.jpg'; 
import { Api, fetchAPI } from '../../../services/httpService';
import Swal from "sweetalert2";
import { useIdleTimer } from 'react-idle-timer';
import { isAllowSessionTime } from '../../../baseURL';
import Modals from "react-bootstrap/Modal";
import { Button } from 'react-bootstrap'
import { url } from "../../../baseURL";
import { socketUrl } from "../../../baseURL";
import socketIoClient from "socket.io-client";

const Header = () => {
    let adminSocket;
    const history = useHistory()
    let timeLeft = 59;
    checkTokenExpired();
    const logo = require('../../../assests/Funder.ai.png');
    const bell = require('../../../assests/bell.png');
    const circleWithName = require('../../../assests/Eclipse.png');

    const [notificationsModal, setNotificationsModal] = useState(false)
    const closeModal = (data) => {
        setNotificationsModal(data)
    }

    const logout = async () => {
        Swal.fire({
            title: 'Are you sure!',
            text: 'Do you want to logout?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            confirmButtonColor: "var(--bg-theme)",
        }).then(result => {
            if (result.isConfirmed) {
                const res = Api('post', '/users/logout');
                if (res) {
                    window.localStorage.clear();
                    window.location = '/';
                }
            }
        });
    }

    /* for idel use */
    const [isIdle, setIsIdle] = useState(false);

    const updateTimerHtml = () => {
        return `<div style="color: #009e49; background: #fff; padding: 10px; border-radius: 5px;">
                    You will be logged out in <b>${timeLeft}</b> seconds.
                </div>`;
    };

    const handleOnIdle = async () => {
        setIsIdle(true);
        let loginUserId = localStorage.getItem("_id");
        // Show the Swal alert and store the promise
        const result = await Swal.fire({
            title: 'You have been inactive',
            html: updateTimerHtml(),
            icon: 'warning',
            timer: 60 * 1000,
            timerProgressBar: true,
            showCancelButton: false,
            confirmButtonText: 'Stay Logged In',
            allowOutsideClick: true,
            didOpen: () => {
                // Start the timer interval when the alert opens
                const timerInterval = setInterval(() => {
                    if (timeLeft > 0) {
                        timeLeft -= 1;
                        // Update the HTML of the specific Swal instance
                        const htmlContainer = Swal.getHtmlContainer();
                        if (htmlContainer) {
                            htmlContainer.innerHTML = updateTimerHtml();
                        }
                    } else {
                        clearInterval(timerInterval);
                    }
                }, 1000);
            }
        });

        if (result.isConfirmed) {
            // Reset the idle state and refresh the session
            // setIsIdle(false);
            try {
                // Call refresh token API
                const resultOtp = await fetchAPI('POST', `/common/generateVerificationOTP?userId=${loginUserId}&event=refreshToken&sendTo=email`);
                if (resultOtp.success) {
                    Swal.fire({
                        title: 'Verify OTP',
                        input: 'text',
                        inputLabel: 'Please enter the OTP sent to your email.',
                        inputPlaceholder: 'Enter OTP',
                        inputAttributes: {
                            maxlength: 6,
                            autocapitalize: 'off',
                            autocorrect: 'off'
                        },
                        showCancelButton: false,
                        confirmButtonText: 'Submit',
                        allowOutsideClick: false,
                        preConfirm: async (otp) => {
                            if (!otp) {
                                Swal.showValidationMessage('Please enter a valid OTP.');
                                return false; // Return false to prevent submission
                            }
                            // Retrieve the user ID and token
                            const loginUserId = localStorage.getItem("_id");
                            const currentToken = localStorage.getItem("_token");
                            const cleanToken = currentToken.replace("Bearer ", "");
                            try {
                                // Call the API to validate the OTP
                                const res = await fetchAPI("POST", `/common/validateVerificationOTP?userId=${loginUserId}&event=refreshToken&otp=${otp}&oldToken=${cleanToken}`);
                                if (res.success) {
                                    // Set the new token and reload the window
                                    localStorage.setItem("_token", "Bearer " + res.body); // Assuming `res.body` contains the new token
                                    window.location.reload(); // Reload the window after updating the token
                                } else {
                                    // Swal.showValidationMessage('Invalid OTP,Please try again.');
                                    /* logOut User. */
                                    // Optional: Logout the app if necessary
                                    const res = await Api('post', '/users/logout');
                                    if (res) {
                                        Swal.close();
                                        window.localStorage.clear();
                                        setTimeout(() => {
                                            history.push('/');
                                        }, 1000);
                                    }
                                    setIsIdle(false);
                                }
                            } catch (error) {
                                // Swal.showValidationMessage('An error occurred while validating the OTP.');
                                // Optional: Logout the app if necessary
                                const res = await Api('post', '/users/logout');
                                if (res) {
                                    Swal.close();
                                    window.localStorage.clear();
                                    setTimeout(() => {
                                        history.push('/');
                                    }, 1000);
                                }
                                setIsIdle(false);
                            }
                        }
                    }).then((result) => {
                        if (result.isConfirmed) {
                            // console.log("Submitted OTP:", result.value); // Handle OTP submission if needed
                        } else {
                            // console.log('OTP input cancelled');
                        }
                    });
                }
            } catch (error) {
                // Optional: Logout the app if necessary
                const res = await Api('post', '/users/logout');
                if (res) {
                    Swal.close();
                    window.localStorage.clear();
                    setTimeout(() => {
                        history.push('/');
                    }, 1000);
                }
                setIsIdle(false);
            }
        } else if (result.isDismissed && result.dismiss === Swal.DismissReason.timer) {
            // Optional: Logout the app if necessary
            const res = await Api('post', '/users/logout');
            if (res) {
                Swal.close();
                window.localStorage.clear();
                setTimeout(() => {
                    history.push('/');
                }, 1000);
            }
            setIsIdle(false);
        } else if (result.isDismissed && result.dismiss === Swal.DismissReason.backdrop) {
            setIsIdle(false);
        }
    };

    const handleOnActive = () => {
        setIsIdle(false);
    };

    const { getElapsedTime, getLastActiveTime } = useIdleTimer({
        timeout: isAllowSessionTime, // 1 minute
        // timeout: 1 * 30 * 1000, // 1 minute
        onIdle: handleOnIdle,
        onActive: handleOnActive,
        debounce: 500,
    });

    const callFunctionEvery10Min = async () => {
        const currentTimestmp = Date.now();
        const lastActive = getLastActiveTime();
        let diffTimestmp = (currentTimestmp - lastActive) / 1000
        // Aap yaha background me function call kar sakte ho
        if (diffTimestmp < 600) {
            try {
                // Call the API for refresh token
                let param = {
                    userId: localStorage.getItem("_id"),
                    oldToken: localStorage.getItem("_token").replace("Bearer ", ""),
                }
                const res = await fetchAPI('POST', `/refreshToken`, param);
                if (res.success) {
                    // Set the new token and reload the window
                    localStorage.setItem("_token", "Bearer " + res.body); // Assuming `res.body` contains the new token
                } else {
                    /* logOut User. */
                    // Optional: Logout the app if necessary
                    const res = await Api('post', '/users/logout');
                    if (res) {
                        Swal.close();
                        window.localStorage.clear();
                        setTimeout(() => {
                            history.push('/');
                        }, 1000);
                    }
                }
            } catch (error) {
                // console.log('VerifyTokenOtp-error', error);
            }
        }
    };

    useEffect(() => {
        socktCall()
        const interval = setInterval(callFunctionEvery10Min, 600000); // 600000 ms = 10 minutes
        return () => clearInterval(interval);
    }, []);



    const socktCall = () => {
        const SockJS = window.SockJS;
        const Stomp = window.Stomp;
        var socket = new SockJS(url + '/funderSocket');
        var stompClient = Stomp.over(socket);
        let chkUsrId = localStorage.getItem("_id");
        // let currentToken = localStorage.getItem("_token");
        stompClient.connect({}, function (frame) {
            // stompClient.setConnected(true);
            stompClient.subscribe('/ADCB/TokenExpired', function (resSocket) {
                let result = JSON.parse(resSocket.body);
                // console.log('result-token expire')
                console.log('result-socket', result.text)
                if (result && result.text === 'Expired' && result.from === chkUsrId) {
                    const resToken = 'Bearer ' + result.token;
                    let currentToken = localStorage.getItem("_token");
                    console.log('resToken',resToken)
                    if(currentToken === resToken){
                        // alert('expired Token')
                        const res = Api('post', '/users/logout');
                        if (res) {
                            window.localStorage.clear();
                            localStorage.setItem('isTimeSessionOutAD', true)
                            window.location.reload();
                        }
                        stompClient.disconnect();
                    }
                }
            });
        });
    }

    return (
        <div>
            <div id="header">
                <div className="row align-items-center">
                    <div className="col-6 pl-0">
                        <div className="top_left_title"><img src={logo} /></div>
                    </div>
                    <div className="col-6 text-right">
                        <div className="bell cursor">
                            <img src={bell} width={17} height={17} onClick={() => {
                                setNotificationsModal(true)
                            }} />
                        </div>

                        <Link className="ml-4 headerLogOut" to="#" onClick={() => logout()}>
                            <FontAwesomeIcon color='var(--color-f2)' icon={faSignOutAlt} />
                        </Link>

                    </div>
                </div>
            </div>
            {notificationsModal && (
                <Notifications
                    openModal={notificationsModal}
                    closeModal={closeModal}
                    data={[]}
                />
            )}
        </div>
    );
}

export default Header;