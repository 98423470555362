import t from "./types";
import { Api } from '../../services/httpService';


export const setFilterData = (data) => async (dispatch) => {
    await dispatch({ type: t.SET_FILTER_DATA, payload: data })
}


export const resetFilterData = () => async (dispatch) => {
    await dispatch({ type: t.RESET_FILTER_DATA })
}

export const getFilterData = (param, filterFor, pageNo, size,history) => async (dispatch) => {
    // console.log("param", param, "filterFor", filterFor, "pageNo", pageNo, "size", size);
    let pageSize = {
        "size": size ?? 10,
        "page": pageNo ?? 0
    }

    for (let prop in param) if (!param[prop]) delete param[prop];

    let returnedTarget = Object.assign(param, pageSize);

    var res = await Api('post', `/${filterFor}/findAsParam`, returnedTarget);
    // console.log('filter Data',res)
    if (res.success) {
        // console.log('res.body',res.body,filterFor)
        if (filterFor == "common/accountDocument") {
            await dispatch({ type: t.GET_DOCUMENT_LIBRARY_DATA, payload: res.body })
        } else {
            await dispatch({ type: t.GET_FILTER_DATA, payload: res.body })
        }
    } else {
        if(res.status == 401){
            window.location = '/admin/page/UnAuthorized';
        }
        else{
            console.log("Pagination error", res);
        }
    }
}
export const getBankPaymentFilterData = (param, pageNo, size) => async (dispatch) => {
    let pageSize = {
        "size": size ?? 10,
        "page": pageNo ?? 0
    }
    for (let prop in param) if (!param[prop]) delete param[prop];
    let returnedTarget = Object.assign(param, pageSize);
    var res = await Api('post', `/bank/bankpayment/findAsParam`, returnedTarget);
    if (res.success) {
        await dispatch({ type: t.GET_BANK_PAYMENT_FILTER_DATA, payload: res.body })
    } else {
        if(res.status == 401){
            window.location = '/admin/page/UnAuthorized';
        }
        else{
            console.log("Pagination error", res);
        }
    }
}

export const getFilterDataPayments = (param, filterFor, pageNo) => async (dispatch) => {
    let pageSize = {
        "size": 10,
        "page": pageNo ? pageNo : 0
    }
    for (let prop in param) if (!param[prop]) delete param[prop];
    let returnedTarget = Object.assign(param, pageSize);
    var res = await Api('post', `/${filterFor}/findAsParam`, returnedTarget);
    if (res.success) {
        await dispatch({ type: t.GET_FILTER_DATA_PAYMENTS, payload: res.body })
    } else {
        if(res.status == 401){
            window.location = '/admin/page/UnAuthorized';
        }
        else{
            console.log("Pagination error----", res);
        }
    }
}


export const getFilterDataWallet = (param, filterFor, pageNo) => async (dispatch) => {
    let pageSize = {
        "size": 10,
        "page": pageNo ? pageNo : 0
    }
    for (let prop in param) if (!param[prop]) delete param[prop];
    let returnedTarget = Object.assign(param, pageSize);
    var res = await Api('post', `/${filterFor}/findAsParam`, returnedTarget);
    if (res.success) {
        await dispatch({ type: t.GET_FILTER_DATA_WALLET, payload: res.body })
    } else {
        if(res.status == 401){
            window.location = '/admin/page/UnAuthorized';
        }
        else{
            console.log("Pagination error----", res);
        }
    }
}


export const getExportData = (param, filterFor) => async (dispatch) => {
    let pageSize = {
        "size": 0,
        "page": 0
    }

    let returnedTarget = Object.assign({}, param, pageSize);
    var res = await Api('post', `/${filterFor}/findAsParam`, returnedTarget);
    if (res.success) {

        switch (filterFor) {
            case "invoices":
                await dispatch({ type: t.GET_EXPORT_DATA, payload: res.body })
                break;
            case "payments":
                await dispatch({ type: t.GET_EXPORT_PAYMENT_DATA, payload: res.body })
                break;
            case "PurchaseOrder":
                await dispatch({ type: t.GET_EXPORT_PURCHASE_ORDER_DATA, payload: res.body })
                break;
            case "pending":
                await dispatch({ type: t.GET_EXPORT_PENDING_DATA, payload: res.body })
                break;
            case "wallet":
                await dispatch({ type: t.GET_EXPORT_WALLET_DATA, payload: res.body })
                break;
            case "traditionalrequests":
                await dispatch({ type: t.GET_EXPORT_REQUEST_DATA, payload: res.body })
                break;
            default:
                break;
        }
    } else {
        console.log("Export Data", res);
    }
}


export const setSearchData = (paramData, searchFor) => async (dispatch) => {
    try {
        let param = paramData ? paramData : {};
        // console.log('setSearchData-param',param,searchFor);
        dispatch({ type: t.SET_SEARCH_DATA, payload: param, requestFor: searchFor })
    } catch (error) {
        console.log("search result error", error);
    }
}

export const getBankRecieptFilterData = (param, pageNo, size) => async (dispatch) => {
    let pageSize = {
        "size": 10,
        "page": pageNo ? pageNo : 0
    }
    for (let prop in param) if (!param[prop]) delete param[prop];
    let returnedTarget = Object.assign(param, pageSize);
    var res = await Api('post', `/bank/adcb/findAsParam`, returnedTarget);
    if (res.success) {
        await dispatch({ type: t.GET_BANK_RECIEPT_FILTER_DATA, payload: res.body })
    } else {
        if(res.status == 401){
            window.location = '/admin/page/UnAuthorized';
        }
        else{
            console.log("Pagination error----", res);
        }
    }
}

export const getAmazonFilterData = (param, pageNo, size) => async (dispatch) => {
    let pageSize = {
        "size": 10,
        "page": pageNo ? pageNo : 0
    }
    for (let prop in param) if (!param[prop]) delete param[prop];
    let returnedTarget = Object.assign(param, pageSize);
    var res = await Api('post', `/amazon/findAsParam`, returnedTarget);
    if (res.success) {
        await dispatch({ type: t.GET_AMAZON_FILTER_DATA, payload: res.body })
    } else {
        if(res.status == 401){
            window.location = '/admin/page/UnAuthorized';
        }
        else{
            console.log("Pagination error----", res);
        }
    }
}

