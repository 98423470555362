import t from '../../actions/EmailTemplateAction/types';

const initialState = {
    emailTemplate: undefined,
    emailTemplateError: undefined,
    emailTemplateLoading: false,

    emailTemplateEntry: undefined,
    emailTemplateEntryError: undefined,
    emailTemplateEntryLoading: false
};
export default (state = initialState, action) => {
    switch (action.type) {
        case t.FETCHED_EMAIL_TEMPLETE:
            //console.log("FETCHED_NOTIFICATION_TEMPLETE", action.payload)
            return {
                ...state,
                emailTemplate: action.payload,
            };
        case t.FETCHED_EMAIL_TEMPLETE_ERROR:
            return {
                ...state,
                emailTemplateError: action.payload,
            };

        case t.FETCHED_EMAIL_TEMPLETE_LOADING:
            return {
                ...state,
                emailTemplateLoading: action.payload,
            };

        case t.EMAIL_TEMPLETE_ENTRY:
            return {
                ...state,
                emailTemplateEntry: action.payload,
            };
        case t.EMAIL_TEMPLETE_ENTRY_ERROR:
            return {
                ...state,
                emailTemplateEntryError: action.payload,
            };

        case t.EMAIL_TEMPLETE_ENTRY_LOADING:
            return {
                ...state,
                emailTemplateEntryLoading: action.payload,
            };



        default:
            return state;
    }
};
