import t from "../../actions/AuthActions/types";

const initialState = {
  userData: "",

}
export default (state = initialState, action) => {
  switch (action.type) {
    case t.VERIFY_OTP:
      return {
        ...state,
        userData: action.payload
      }
      break;
    default:
      return state;
  }
}
