import t from "../../actions/PartnerActions/types";
const initialState = {
    partnersList: {
        content: []
    },
    partnerFactParams: {
        content: []
    },
    partnerData: {},
    factParamData: {},
    partnerBanks: []
}

export default (state = initialState, action) => {
    switch (action.type) {
        case t.FETCHED_ALL_PARTNERS:
            return {
                ...state,
                partnersList: action.payload
            }
            break;
        case t.FETCHED_ALL_PARTNERS_FACT_PARAMS:
            return {
                ...state,
                partnerFactParams: action.payload
            }
            break;
        case t.FETCH_PARTNER_BANKS:
            //console.log("Mechant Action ",action.payload);
            return {
                ...state,
                partnerBanks: action.payload
            }
            break;
        case t.FETCH_PARTNER:
            return {
                ...state,
                partnerData: action.payload
            }
            break;
        case t.FETCH_PARTNER_FACT_PARAM:
            return {
                ...state,
                factParamData: action.payload
            }
            break;
        default:
            return state;
            break;
    }
}
