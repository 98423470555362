import t from "./types";
import { Api } from '../../services/httpService';

export const addRecoveries = (param) => async (dispatch) => {
    // console.log("addRecoveries:::::::::::::", param)
    var res = await Api('post', `/pfpclient/recoveries/addorupdate`, param);
    if (res.success) {
        console.log("Add recoveries", res);
    } else {
        console.log("Pagination error", res);
    }
}


export const getSingleRecovery = (id) => async (dispatch) => {
    var res = await Api('get', `/pfpclient/recoveries/${id}`);
    if (res.success) {
        dispatch({ type: t.GET_SINGLE_RECOVERIES, payload: res.body })
    } else {
        console.log("Pagination error", res);
    }
}


export const getPendingRecoveries = (pendingId) => async (dispatch) => {
    let param = {
        "requestPendingId": pendingId,
        "recoveryType": "Pending"
    }
    var res = await Api('post', `/pfpclient/recoveries/findAsParam`, param);
    if (res.success) {
        dispatch({ type: t.GET_PENDING_RECOVERIES_DATA, payload: res.body })
    } else {
        console.log(res);
    }
}


export const getRequestRecoveries = (requestId) => async (dispatch) => {
    let param = {
        "requestPendingId": requestId,
        "recoveryType": "Request"
    }
    var res = await Api('post', `/pfpclient/recoveries/findAsParam`, param);
    if (res.success) {
        dispatch({ type: t.GET_REQUEST_RECOVERIES_DATA, payload: res.body })
    } else {
        console.log(res);
    }
}

export const deleteRecovery = (id) => async (dispatch) => {
    let param = {
        "recoveryId": id
    }
    var res = await Api('post', `/pfpclient/recoveries/delete`, param);

    if (res.success) {
        dispatch({ type: t.DELETE_RECOVERY, payload: res.body })
    } else {
        console.log(res);
    }
}

