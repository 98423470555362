import t from "./types";
import { Api } from "../../services/httpService";


export const getMerchantList = (status=false) => async (dispatch) => {
    let res;
    if(status){
        res = await Api('get', `/merchant?accountStatus=Active,Deactivated,OnHold,OnBoarding,NewLead&userRoles=2`);
    }
    else{
        res = await Api('get', `/merchant?accountStatus=Active,Deactivated,OnHold&userRoles=2`);
    }
    if (res.success) {
        await dispatch({ type: t.FETCHED_MERCHANT, payload: res.body });
    } else {
        await dispatch({ type: t.FETCHED_MERCHANT, payload: res.body });
    }
}


export const getAllMerchantList = () => async (dispatch) => {
    const res = await Api('get', `/merchant`);
    if (res.success) {
        await dispatch({ type: t.FETCHED_ALL_MERCHANT, payload: res.body });
    } else {
        await dispatch({ type: t.FETCHED_ALL_MERCHANT, payload: res.body });
    }
}

export const Login = (params) => async (dispatch) => {
    const res = await Api('post', '/users/login', params);
    await dispatch({ type: t.LOGIN, payload: res });
}


export const getNotifications = (param) => async (dispatch) => {
    const res = await Api('POST', '/notifications/findAsParamMsg', param);
    if (res.success) {
        dispatch({ type: t.GET_NOTIFICATION_LIST, payload: res });
    } else {
        console.log("error", res);
    }
}


export const getRemittance = (value) => async (dispatch) => {
    try {
        let res = await Api('POST', "/payments/findAsParam", value);
        if (res.success) {
            dispatch({ type: t.GET_REMITTANCE, payload: res.body })
        } else {
            if(res.status == 401){
                window.location = '/admin/page/UnAuthorized';
            }
            else{
                console.log("error----", res);
            }
        }
    } catch (error) {
        console.log("error", error);
    }
}


export const getInvestor = () => async (dispatch) => {
    try {
        const res = await Api('GET', '/pfpclient/findAll');
        if (res.success) {
            dispatch({ type: t.GET_INVESTOR_LIST, payload: res });
        } else {
            if(res.status == 401){
                window.location = '/admin/page/UnAuthorized';
            }
            else{
                console.log("error----", res);
            }
        }
    } catch (error) {
        console.log("error", error);
    }
}

export const getInvestorDetails = (investorId) => async (dispatch) => {
    try {
        const res = await Api('GET', `/pfpclient/${investorId}`);
        if (res.success) {
            dispatch({ type: t.GET_INVESTOR_DETAILS, payload: res });
        } else {
            console.log("error", res);
        }
    } catch (error) {
        console.log("error", error);
    }
}

export const getMerchantDetails = (merchantId) => async (dispatch) => {
    try {
        const res = await Api('GET', `/merchant/${merchantId}`);
        if (res.success) {
            dispatch({ type: t.GET_MERCHANT_DETAILS, payload: res });
        } else {
            console.log("error", res);
        }

    } catch (error) {
        console.log("error", error);
    }
}

export const addUpdateMerchant = (param) => async (dispatch) => {
    try {
        const res = await Api('POST', `/merchant/entry`, param);
    } catch (error) {
        console.log("error", error);
    }
}

export const setMerchantFilter = (param) => async(dispatch) => {
    await dispatch({ type: t.SET_CUSTOM_FILTER, payload: param})
}

export const verifyEmail = (params) => async (dispatch) => {
    const res = await Api('post', `/users/verifyEmail`, params,true)
    if (res) {
        await dispatch({ type: t.VERIFY_EMAIL,payload: res })
    }
}

export const verifyOtp = (params) => async (dispatch) => {
    const res = await Api('post', `/users/verifyOTP`, params, true)
    if (res) {
        await dispatch({ type: t.VERIFY_OTP, payload: res })
    }
}

export const changPassword = (params) => async (dispatch) => {
    const res = await Api('post', `/users/changePswd`, params, true)
    if (res) {
        await dispatch({ type: t.CHANGE_PSWD, payload: res })
    }
}