import Axios from "axios";
import { url } from "../baseURL";

export const Api = async (reqType, apiRouteUrl, body, isFormData = false) => {
    try {

        let tokenHeader = "";
        if (localStorage.getItem("_token")) {
            tokenHeader = localStorage.getItem("_token");
        }

        // console.log(url + apiRouteUrl)
        // console.log(tokenHeader)
        if (isFormData === true) {
            const requestOptions = {
                method: reqType,
                headers: {
                    "Authorization": tokenHeader,
                    "menuUrl": window.localStorage.getItem('menuUrl')
                },
                body: body
            };

            let APIURL = url + apiRouteUrl;

            let response = await fetch(APIURL, requestOptions);
            let data = [];
            if (response.status == 200 || response.status == 202) {
                data = await response.json();
            } else {
                data = [];
            }
            return data;

        } else {
            const requestOptions = {
                method: reqType,
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": tokenHeader,
                    "menuUrl": window.localStorage.getItem('menuUrl')
                },
                body: JSON.stringify(body)
            };
            let APIURL = url + apiRouteUrl;
            let response = await fetch(APIURL, requestOptions);

            let data = [];
            if (response.status == 200) {
                data = await response.json();
            } else {
                data = await response.json();
            }
            return data;
        }

    } catch (err) {
        return { success: err }
    }
}

export const fetchAPI = async (reqType, apiRouteUrl, body, headers = {}) => {
    try {
        let finalHeader = Object.assign({}, headers, { "Authorization": localStorage.getItem("_token") ?? "", "menuUrl": window.localStorage.getItem('menuUrl') })
        const requestOptions = {
            url: `${url}${apiRouteUrl}`,
            method: reqType,
            headers: finalHeader,
        };
        if (reqType === "GET" || reqType === "get" || reqType === "Get") {
            requestOptions['params'] = body;
        } else {
            requestOptions['data'] = body;
        }
        let response = await Axios(requestOptions);
        return response.data;
    } catch (err) {
        if (err.response) {
            throw err.response
        }
        throw { message: err.message }
    }
}

export const fetchFileWithId = async (id) => {
    try {
        let res = await Axios({
            method: "get",
            url: `${url}/files/${id}`,
            responseType: "blob",
            headers: {
                "Content-Type": "application/json",
                "Authorization": localStorage.getItem("_token") ?? "",
                "menuUrl": window.localStorage.getItem('menuUrl')
            },
        })
        return res.data;
    } catch (error) {
        throw error;
    }
}

export const fetchFileFromUrl = async (fileUrl) => {
    try {
        let res = await Axios({
            method: "get",
            url: `${url}/common/docView?filePath=${fileUrl}`,
            // responseType:"blob",
            headers: {
                "Content-Type": "application/json",
                "Authorization": localStorage.getItem("_token") ?? "",
                "menuUrl": window.localStorage.getItem('menuUrl')
            },
        })
        return res.data
    } catch (error) {
        if (error.response) {
            throw error.response.data.message
        } else {
            throw error.message
        }
    }
}

export const detectMimeType = (base64String, fileName) => {
    var ext = fileName.substring(fileName.lastIndexOf(".") + 1);
    console.log(ext);
    if (ext === undefined || ext === null || ext === "") ext = "bin";
    ext = ext.toLowerCase();
    const signatures = {
        JVBERi0: "application/pdf",
        R0lGODdh: "image/gif",
        R0lGODlh: "image/gif",
        iVBORw0KGgo: "image/png",
        TU0AK: "image/tiff",
        "/9j/": "image/jpg",
        UEs: "application/vnd.openxmlformats-officedocument.",
        PK: "application/zip",
    };
    for (var s in signatures) {
        if (base64String.indexOf(s) === 0) {
            var x = signatures[s];
            // if an office file format
            if (ext.length > 3 && ext.substring(0, 3) === "ppt") {
                x += "presentationml.presentation";
            } else if (ext.length > 3 && ext.substring(0, 3) === "xls") {
                x += "spreadsheetml.sheet";
            } else if (ext.length > 3 && ext.substring(0, 3) === "doc") {
                x += "wordprocessingml.document";
            }
            // return
            return x;
        }
    }
    // if we are here we can only go off the extensions
    const extensions = {
        xls: "application/vnd.ms-excel",
        ppt: "application/vnd.ms-powerpoint",
        doc: "application/msword",
        xml: "text/xml",
        mpeg: "audio/mpeg",
        mpg: "audio/mpeg",
        txt: "text/plain",
        png: "image/png",
        jpeg: "image/jpeg",
        pdf: "application/pdf"
    };
    for (var e in extensions) {
        if (ext.indexOf(e) === 0) {
            var xx = extensions[e];
            return xx;
        }
    }
    // if we are here - not sure what type this is
    return "unknown";
}
