import React, { Component } from 'react';
import '../node_modules/bootstrap/dist/css/bootstrap.css';
import './App.css';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
import Private from './routes/private';
import { connect } from 'react-redux';
import { PermissionActions } from './actions';
import history from './history';

const Login = React.lazy(() => import('./pages/Login/login'));

class App extends Component {

  constructor(props) {
    super(props);
    this.state = {
      token: window.localStorage.getItem('_token') || ""
    }
  }

  componentDidMount() {
    this.checkToken();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.token !== this.state.token) {
      this.checkToken();
    }
  }

  checkToken() {
    const token = window.localStorage.getItem('_token');
    if (!token) {
      // Token expire ho chuka hai ya null hai, toh login page par redirect karenge
      history.push('/');
    }
  }

  render() {
    return (
      <div>
        <Router history={history}>
          {this.state.token ?
            <Private />
            :
            <React.Suspense fallback={<p>loading...</p>}>
              <Switch>
                <Route exact path="/" component={Login} />
                <Route exact path="/admin/login" component={Login} />
                <Redirect to="/admin/login" />
              </Switch>
            </React.Suspense>
          }
        </Router>
      </div>
    );
  }
}

const mapStateToProps = ({ PermissionReducers }) => {
  return {
    userRoleMenuList: PermissionReducers.userRoleMenuList,
  };
}

const mapDispatchToProps = {
  getUserRoleMenu: PermissionActions.getUserRoleMenu,
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
