import t from "./types";
import { Api } from "../../services/httpService";


export const getBuyerPlatform = () => async (dispatch) => {
    const res = await Api('get', `/buyerPlatforms`);
    if (res) {
        await dispatch({ type: t.FETCH_BUYER_PLATFORM, payload: res._embedded });
    } else {
        await dispatch({ type: t.FETCH_BUYER_PLATFORM, payload: res._embedded });
    }
}

export const getBuyerPlatformDetails = (id) => async (dispatch) => {
    const res = await Api('get', `/buyerPlatforms/${id}`);
    if (res) {
        await dispatch({ type: t.FETCH_BUYER_PLATFORM_DETAILS, payload: res });
    } else {
        console.log("error", res);
    }
}

export const addOrUpdateSubMerchant = (param) => async (dispatch) => {
    const res = await Api("post", '/merchant/submerchant/addorupdate', param);
    if (res) {
        await dispatch({ type: t.ADD_UPDATE_SUB_MERCHANT, payload: res })
    } else {
        console.log("Error in add update submerchant");
    }
}

export const getSubMerchant = (param) => async (dispatch) => {
    const res = await Api("POST", `/merchant/submerchant`, param);
    if (res) {
        await dispatch({ type: t.GET_SUB_MERCHANT, payload: res })
    } else {
        console.log("Error in add update submerchant");
    }
}

export const uploadMoreAttachment = (param) => async (dispatch) => {
    const res = await Api("POST", `/documents/additionalDocs`, param, true);
    if (res) {
        await dispatch({ type: t.UPLOAD_MORE_ATTACHMENT, payload: res })
    } else {
        console.log("Error in uploading data");
    }
}

export const deleteMoreAttachment = (documentId, merchantId, BuyerId) => async (dispatch) => {
    if (merchantId) {
        var res = await Api("POST", `/documents/deleteAdditionalDocs?documentId=${documentId}&merchantId=${merchantId}`, {});
    } else {
        var res = await Api("POST", `/documents/deleteAdditionalDocs?documentId=${documentId}&buyerPlatFormId=${BuyerId}`, {});
    }
    if (res) {
        await dispatch({ type: t.DELETE_MORE_ATTACHMENT, payload: res })
    } else {
        console.log("Error in uploading data");
    }
}

