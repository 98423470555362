import t from "../../actions/RecoveriesActions/types";

const initialState = {
    pendingRecoveries: {
        content: [],
    },
    requestRecoveries: {
        content: [],
    },
    recoveryDetails: {}
};

export default (state = initialState, action) => {
    switch (action.type) {
        case t.GET_PENDING_RECOVERIES_DATA:

            return {
                ...state,
                pendingRecoveries: action.payload,
            };

        case t.GET_REQUEST_RECOVERIES_DATA:

            return {
                ...state,
                requestRecoveries: action.payload,
            };

        case t.GET_SINGLE_RECOVERIES:

            return {
                ...state,
                recoveryDetail: action.payload,
            };



        default:
            return state;
    }
};
